<template>
  <section class="mockup">
    <header>
      <p>{{ nome }}</p>
      <a title="Fechar" @click="close">
        x
      </a>
    </header>
    <div class="informacoes">
      <div>
        <p>
          <strong>{{ $t("dados-mapa.municipio") }}:</strong>
          {{ helipontoAerodromoAeroporto.municipio.nome }}
        </p>
        <p>
          <strong>{{ $t("dados-mapa.altitude") }}:</strong>
          {{ helipontoAerodromoAeroporto.altitude }}m
        </p>
        <template
          v-if="
            helipontoAerodromoAeroporto.tipo == 'aeroporto' ||
              helipontoAerodromoAeroporto.tipo == 'aerodromo'
          "
        >
          <p>
            <strong>{{ $t("dados-mapa.comprimento-pista") }}:</strong>
            {{ helipontoAerodromoAeroporto.comprimento_pista }}m
          </p>
          <p>
            <strong>{{ $t("dados-mapa.largura-pista") }}:</strong>
            {{ helipontoAerodromoAeroporto.largura_pista }}m
          </p>
        </template>
        <template v-if="helipontoAerodromoAeroporto.tipo == 'heliponto'">
          <p>
            <strong>{{ $t("dados-mapa.dimensoes-heliponto") }}:</strong>
            {{ helipontoAerodromoAeroporto.dimensoes_heliponto }}m
          </p>
        </template>
        <p>
          <strong>{{ $t("dados-mapa.operacao") }}: </strong>
          {{ helipontoAerodromoAeroporto.operacao }}
        </p>
        <p>
          <strong>{{ $t("dados-mapa.privado") }}: </strong>
          {{ helipontoAerodromoAeroporto.privado ? $t("sim") : $t("nao") }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "MapasOverlayCardHelipontoAerodromoAeroporto",
  props: {
    helipontoAerodromoAeroporto: {
      type: Object,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
  computed: {
    nome() {
      if (this.helipontoAerodromoAeroporto.tipo === "aerodromo") {
        return `${this.$t("dados-mapa.aerodromo")} ${
          this.helipontoAerodromoAeroporto.nome
        }`;
      } else if (this.helipontoAerodromoAeroporto.tipo === "heliponto") {
        return `${this.$t("dados-mapa.heliponto")} ${
          this.helipontoAerodromoAeroporto.nome
        }`;
      }

      return this.helipontoAerodromoAeroporto.nome;
    },
  },
};
</script>
<style lang="scss" scoped>
.mockup {
  background-color: white;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.356);
  width: 20rem;
  header {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    background-color: #134104;
    color: white;
    p {
      margin: 0;
      font-weight: 800;
    }
    a {
      margin-left: 1rem;
      color: red;
      font-family: "Poppins-Bolder";
      cursor: pointer;
    }
  }
  .informacoes {
    padding: 0.5rem 0;
    p {
      margin: 0.2rem 0.5rem;
    }
  }
}
</style>
