<template>
  <div>
    <div class="centro">
      <h1>{{ $t("mapa-site.titulo") }}</h1>
      <div class="sumario">
        <div class="letra">
          <h2>C</h2>
          <p>
            <span> &#10148;</span
            ><router-link :to="{ name: routeNames.CONTATO }">{{
              $t("mapa-site.contato")
            }}</router-link>
          </p>
        </div>
        <div class="letra">
          <h2>D</h2>
          <p>
            <span> &#10148;</span
            ><router-link :to="{ name: routeNames.DADOS_EM_MAPAS }">{{
              $t("mapa-site.dados-mapa")
            }}</router-link>
          </p>
        </div>
        <div class="letra">
          <h2>I</h2>
          <p>
            <span> &#10148;</span
            ><router-link
              :to="{ name: routeNames.INICIO, hash: '#industrias' }"
              >{{ $t("mapa-site.industria") }}</router-link
            >
          </p>
          <p>
            <span> &#10148;</span
            ><router-link
              :to="{ name: routeNames.INICIO, hash: '#infraestrutura' }"
              >{{ $t("mapa-site.infraestrutura") }}</router-link
            >
          </p>
          <p>
            <span> &#10148;</span
            ><router-link :to="{ name: routeNames.INICIO }">{{
              $t("mapa-site.inicio")
            }}</router-link>
          </p>
        </div>
        <div class="letra">
          <h2>L</h2>
          <p>
            <span> &#10148;</span
            ><router-link
              :to="{ name: routeNames.INICIO, hash: '#linhas-credito' }"
              >{{ $t("mapa-site.linhas-credito") }}</router-link
            >
          </p>
        </div>
        <div class="letra">
          <h2>P</h2>
          <p>
            <span> &#10148;</span
            ><router-link
              :to="{ name: routeNames.INICIO, hash: '#programas' }"
              >{{ $t("mapa-site.programas") }}</router-link
            >
          </p>
        </div>
        <div class="letra">
          <h2>S</h2>
          <p>
            <span> &#10148;</span
            ><router-link :to="{ name: routeNames.INICIO, hash: '#sobre' }">{{
              $t("mapa-site.sobre-goias")
            }}</router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as routeNames from "../router/routeNames";
import metadados from "../helpers/metadados";

export default {
  name: "MapaSite",
  metaInfo() {
    return {
      meta: [...metadados(this.$route)],
    };
  },
  data() {
    return {
      routeNames,
    };
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-family: "Poppins-SemiBold";
  color: #256f0d;
}
h2 {
  color: white;
  background-color: #256f0d;
  display: table;
  width: 2rem;
  text-align: center;
}
.sumario {
  display: grid;
  gap: 0.5rem;
  margin-bottom: 2rem;
  .letra {
    border: #256f0d solid 0.1rem;
    padding: 0.5rem;
    h2 {
      margin: 0;
    }
    p {
      margin: 0.5rem 0;
      span {
        margin-right: 0.5rem;
      }
      a {
        text-decoration: none;
        color: black;
        transition: 0.3s;
      }
      a:hover {
        text-decoration: underline;
        margin-left: 0.5rem;
      }
    }
  }
}
.alto-contraste {
  h1 {
    color: #f7a311;
  }
  h2 {
    background-color: #f7a311;
  }
  .letra {
    border: #f7a311 solid 0.1rem;
    p {
      span {
        color: white;
      }
      a {
        color: white;
      }
    }
  }
}
@media screen and (min-width: "40em") {
  .sumario {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (min-width: "64em") {
  .sumario {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (min-width: "70em") {
  .sumario {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
</style>
