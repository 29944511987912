import axios from "axios";
import axiosInstance from "../axiosInstance";

export default class InfraestruturasApi {
  cancelTokens = {
    getInfraestruturas: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getInfraestruturas() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getInfraestruturas = source;

    const {
      data
    } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/infraestruturas`, {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getInfraestruturas = null;

    return data;
  }
}