<template>
  <div class="row1">
    <div class="disp-flex">
      <p>Área de construção industrial:</p>
      <button type="button" @click="adicionar">+ Adicionar</button>
    </div>
    <div
      class="item"
      v-for="(areaDisponivel, index) in formularioAreasDisponiveis"
      :key="index"
    >
      <div class="item-2">
        <p for="">Descrição:<span>(Obrigatório)</span></p>
        <div class="campo-input">
          <input
            class="effect-1"
            type="text"
            placeholder="Sua Resposta"
            :value="areaDisponivel.descricao"
            required
            @input="
              (evt) => valorAlterado(index, 'descricao', evt.target.value)
            "
          />
          <span class="focus-border"></span>
        </div>
      </div>
      <div class="item-2">
        <p for="">Área m²:<span>(Obrigatório)</span></p>
        <div class="campo-input">
          <input
            class="effect-1"
            placeholder="Sua Resposta"
            type="text"
            required
            :value="areaDisponivel.area_m2"
            @input="(evt) => valorAlteradoM2(index, 'area_m2', evt)"
          />
          <span class="focus-border"></span>
        </div>
      </div>

      <button class="bt-delete" type="button" @click="excluir(index)">
        <IconDelete /> Excluir
      </button>
    </div>
  </div>
</template>

<script>
import IconDelete from "../../assets/images/icon-delete.svg";

const cloneDeep = require("lodash.clonedeep");

export default {
  name: "FormAreasDisponiveis",
  components: {
    IconDelete,
  },
  model: {
    prop: "formularioAreasDisponiveis",
    event: "change",
  },
  props: {
    formularioAreasDisponiveis: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    adicionar() {
      const formularioAreasDisponiveis = cloneDeep(
        this.formularioAreasDisponiveis
      );

      formularioAreasDisponiveis.push({
        descricao: null,
        area_m2: null,
        formulario_informacao_municipal_id: null,
      });

      this.$emit("change", formularioAreasDisponiveis);
    },
    excluir(index) {
      const formularioAreasDisponiveis = cloneDeep(
        this.formularioAreasDisponiveis
      );

      formularioAreasDisponiveis.splice(index, 1);

      this.$emit("change", formularioAreasDisponiveis);
    },
    valorAlteradoM2(index, key, event) {
      if (event.which >= 37 && event.which <= 40) return;
      let value = event.target.value;

      value = value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");

      this.valorAlterado(index, key, value);
    },
    valorAlterado(index, key, value) {
      const formularioAreasDisponiveis = cloneDeep(
        this.formularioAreasDisponiveis
      );

      formularioAreasDisponiveis[index][key] = value;

      this.$emit("change", formularioAreasDisponiveis);
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  span {
    color: red;
  }
}
.disp-flex {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 2rem;
  p {
    margin: 0rem;
    font-family: "Poppins-Medium";
  }
  button {
    border: solid 0.15rem #256f0d;
    background-color: transparent;
    font-family: "Poppins-Medium";
    color: #256f0d;
    font-size: 1rem;
    border-radius: 0.2rem;
    margin: 0 auto 0.5rem;
    transition: 0.2s;
    padding: 0.2rem 1rem;
    cursor: pointer;
  }
  button:hover {
    background-color: #256f0d;
    color: white;
  }
}
.item {
  display: grid;
  gap: 1rem;
  margin: 1rem 0;
  background-color: #ececec;
  padding: 1rem;
  border-radius: 0.2rem;
  .campo-input {
    position: relative;
    :focus {
      outline: none;
    }
    input[type="text"],
    input[type="number"] {
      color: black;
      background-color: transparent;
      width: 100%;
      box-sizing: border-box;
      letter-spacing: 1px;
      height: 1.5rem;
      font-size: 1rem;
      // width: calc(100% - 0.5rem);
      border: 0;
      border-bottom: solid 0.1rem rgb(105, 105, 105);
    }

    .effect-1 {
      border: 0;
      background-color: transparent;
      padding: 7px 0;
      border-bottom: 1px solid #ccc;
    }

    .effect-1 ~ .focus-border {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 2px;
      background-color: #256f0d;
      transition: 0.4s;
    }
    .effect-1:focus ~ .focus-border {
      width: 100%;
      transition: 0.4s;
      outline: none;
    }
  }
  p {
    margin: 0 0 0.5rem;
    font-family: "Poppins-Medium";
    span {
      color: red;
    }
  }
  .bt-delete {
    border: none;
    font-size: 1rem;
    color: red;
    font-family: "Poppins-Medium";
    border-radius: 0.3rem;
    height: 2rem;
    cursor: pointer;
    svg {
      fill: red;
      height: 1rem;
      width: 1rem;
    }
  }
}
@media screen and(min-width: '28rem') {
  .disp-flex {
    justify-content: flex-start;
    p {
      margin: 0;
    }
    button {
      margin: 0 0 0 1rem;
    }
  }
}
@media screen and(min-width: '31.5rem') {
  .disp-flex {
    margin-top: 1rem;
  }
  .item {
    grid-template-columns: 3fr 1fr 1fr;
    align-items: end;
  }
}
</style>
