<template>
  <div id="the_menu">
    <div class="faixa-topo">
      <div class="centro">
        <router-link
          :to="{ name: routeNames.INICIO }"
          active-class="icone-selecionado"
        >
          <img
            src="../assets/images/logo-investe.png"
            class="logo-investe logo-diurno"
            alt="Logo do Investe Goiás"
          />

          <img
            src="../assets/images/logo-investe-bk.png"
            class="logo-investe logo-noturno"
            alt="Logo do Investe Goiás"
          />
        </router-link>
      </div>
    </div>

    <input class="checkmenu" id="atv-menu" type="checkbox" />
    <label for="atv-menu">
      <div class="menu">
        <span class="hamburger"></span>
      </div>
    </label>

    <div class="faixa-menu">
      <div class="centro">
        <TheAcessibilidade />

        <router-link
          :to="{ name: routeNames.INICIO, hash: '#sobre' }"
          @click.native="fecharMenu"
          >{{ $t("menu.sobre-goias") }}</router-link
        >
        <router-link
          :to="{ name: routeNames.INICIO, hash: '#industrias' }"
          @click.native="fecharMenu"
          >{{ $t("menu.industria") }}</router-link
        >
        <router-link
          :to="{ name: routeNames.INICIO, hash: '#linhas-credito' }"
          @click.native="fecharMenu"
          >{{ $t("menu.linhas-credito") }}</router-link
        >
        <router-link
          :to="{ name: routeNames.DADOS_EM_MAPAS }"
          @click.native="fecharMenu"
          >{{ $t("menu.dados-mapa") }}</router-link
        >
        <router-link
          :to="{ name: routeNames.CONTATO }"
          @click.native="fecharMenu"
          >{{ $t("menu.contato") }}</router-link
        >
        <router-link
          :to="{ name: routeNames.MAPA_SITE }"
          @click.native="fecharMenu"
          >{{ $t("menu.mapa-site") }}</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import * as routeNames from "../router/routeNames";

import TheAcessibilidade from "../components/TheAcessibilidade";

export default {
  name: "TheMenu",
  components: {
    TheAcessibilidade,
  },
  data() {
    return {
      routeNames,
    };
  },
  methods: {
    fecharMenu() {
      document.getElementById("atv-menu").checked = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.faixa-topo {
  position: fixed;
  background-color: white;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
  padding: 0.5rem;
  width: 100%;
  text-align: center;
  top: 0;
  z-index: 99;
  img {
    height: 3rem;
    max-width: 80%;
  }
  .logo-noturno {
    display: none;
  }
}
.menu {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 1.5rem;
  left: 0.5rem;
  z-index: 100;

  .hamburger {
    display: block;
    background-color: #256f0d;
    width: 1.4rem;
    height: 2px;
    position: relative;
    z-index: 12;
    margin: 1rem auto;
  }

  .hamburger::before,
  .hamburger::after {
    background-color: #256f0d;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    transition: 0.5s ease-in-out;
  }

  .hamburger::before {
    top: 0.4em;
  }

  .hamburger::after {
    bottom: 0.35em;
  }
}

.checkmenu {
  display: none;
}
input:checked ~ label {
  .hamburger {
    transform: rotate(45deg);
  }
  .hamburger::before,
  .hamburger::after {
    transform: rotate(90deg);
    top: 0;
  }
}
input:checked ~ .faixa-menu {
  left: 0;
}
.faixa-menu {
  position: fixed;
  top: 4.5rem;
  background-color: #256f0d;
  height: calc(100vh - 4.5rem);
  min-height: 100%;
  width: 100%;
  z-index: 98;
  left: -110%;
  transition: 0.3s ease-in-out;
  padding-bottom: 2rem;
  align-content: baseline;
  .centro {
    display: grid;
  }
  a {
    text-align: center;
    align-self: center;
    text-decoration: none;
    color: white;
    padding: 0.5rem;
  }
}
.alto-contraste {
  .faixa-topo {
    background-color: black;
    box-shadow: 0 0.1rem 0 white;
    .logo-diurno {
      display: none;
    }
    .logo-noturno {
      display: inline-block;
    }
    .icone-selecionado{
      box-shadow: none;
    }
  }
  .menu {
    .hamburger,
    .hamburger::after,
    .hamburger::before {
      background-color: white;
    }
  }
  .faixa-menu {
    background-color: #242424;
  }
}
@media screen and (min-width: "64em") {
  .faixa-topo {
    position: static;
    text-align: left;
    width: auto;
    img {
      height: 4rem;
    }
  }
  .menu {
    display: none;
  }
  .faixa-menu {
    left: 0;
    height: auto;
    padding: 0;
    position: static;
    a {
      margin-right: 1rem;
    }
    .router-link-exact-active {
      font-family: "Poppins-Medium";
      background-image: linear-gradient(to top, #0449043d, #25670d);
    }
    .centro {
      position: relative;
      display: flex;
      padding: 0 0.5rem;
    }
  }
  .alto-contraste {
    .router-link-exact-active {
      background-image: none;
      box-shadow: inset 0 -0.1rem 0 #f7a311;
    }
  }
}
</style>
