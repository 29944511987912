import axios from "axios";
import axiosInstance from "./axiosInstance";

export default class CategoriasIndustriaisApi {
  cancelTokens = {
    getCategoriasIndustriais: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getCategoriasIndustriais() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getCategoriasIndustriais = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/categorias_industriais`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getCategoriasIndustriais = null;

    return data;
  }
}
