<template>
  <div>
    <div class="apresentacao centro">
      <h1>Senhor Prefeito,</h1>
      <p>
        Desde junho de 2021, temos novidades animadoras. Com a aprovação da lei
        estadual nº 21.021 de 8 de junho desse ano, está valendo o programa
        “Goiás Gera Emprego e Renda”, mais um importante passo do governo
        estadual pra descentralizar a produção do agro e da indústria e
        estimular o desenvolvimento econômico e social dos municípios, aonde
        quer que estejam localizados. Vamos literalmente gerar emprego e renda,
        com foco em reduzir desigualdades regionais e sociais. Como? O primeiro
        passo é ouvir você, prefeito, que melhor conhece sua cidade. Queremos
        mapear o que sua região tem de melhor.
      </p>
      <p>
        Quais são as oportunidades e potencialidades de negócios que existem na
        sua região? No que tem investido a iniciativa privada? Quais parcerias,
        colaborações técnico-operacionais ou convênios, com entes públicos e
        privados, podem ser formalizados?
      </p>
      <p>
        Então cabe à SIC avançar com o projeto Mapeamento de Oportunidades de
        Negócios, cujo propósito é reunir dados do estado todo e, a partir
        deles, criar um grande portfólio de oportunidades, que vai ser usado pra
        prospecção conjunta de investimentos, seja do exterior ou de outros
        estados.
      </p>
      <p>
        Então, vamos começar? Peço que preencha, de forma criteriosa e completa,
        um formulário abaixo que será nosso primeiro contato. É fácil e rápido.
        A partir dele, nossos especialistas e técnicos da Superintendência de
        Prospecção de Investimentos vão fazer contato e explanar os próximos
        passos.
      </p>
      <p>
        Não me canso de falar no quanto acredito na força do trabalho. E
        trabalho em conjunto então, esse não tem preço. Te convido a
        escrevermos, juntos, um novo passo da nossa história.
      </p>
      <p class="text-center">Atenciosamente,</p>
      <p class="text-center">
        <span class="assina">
          <span class="assinatura">Joel de Sant’Anna Braga Filho</span>
          <span>Secretário de Indústria, Comercio e Serviço</span>
        </span>
      </p>
      <!--<p class="dados">
        Dúvidas ou mais informações:
        <span>
          <a href="tel:+556232015580">(62) 3201-5580</a>
          <a href="tel:+5562984027237">(62) 9 8402-7237 </a>
          <a href="mailto:spi.sic@goias.gov.br">spi.sic@goias.gov.br</a>
        </span>
      </p>-->
    </div>
    <div class="acessar-form">
      <form class="centro">
        <label for="acessoform"
          >Insira o Código enviado via e-mail para acessar o formulário:</label
        >
        <div class="disp-flex">
          <input
            type="text"
            name="Código de Acesso"
            id="acessoform"
            v-model="codigo"
          />
          <button
            type="button"
            @click="verificarCodigo"
            :disabled="!codigo || codigo.trim().length == 0"
            v-if="!verificandoCodigo"
          >
            Formulário <IconAcessar />
          </button>
          <AppLoading size="small" texto="" v-if="verificandoCodigo" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AppLoading from "../AppLoading";
import FormularioInformacoesMunicipaisApi from "../../services/informacoes_municipais/FormularioInformacoesMunicipaisApi";

import IconAcessar from "../../assets/images/icon-acess.svg";

const get = require("lodash.get");

export default {
  name: "InformacoesMunicipaisCodigo",
  components: {
    IconAcessar,
    AppLoading,
  },
  data() {
    return {
      verificandoCodigo: false,
      codigo: null,
    };
  },
  created() {
    this.formularioInformacoesMunicipaisApi = new FormularioInformacoesMunicipaisApi();
  },
  methods: {
    async verificarCodigo() {
      try {
        this.verificandoCodigo = true;

        const codigoMunicipioMapeamentoOportunidade = await this.formularioInformacoesMunicipaisApi.getCodigoMunicipioMapeamentoOportunidade(
          this.codigo
        );

        this.verificandoCodigo = false;
        this.$emit(
          "codigo-foi-validado",
          codigoMunicipioMapeamentoOportunidade
        );
      } catch (err) {
        console.log(err);
        const mensagem = get(
          err,
          "response.data.mensagem",
          "Houve um erro ao realizar a requisição. Por favor recarregue a página e tente novamente"
        );
        this.verificandoCodigo = false;

        this.$swal({
          title: "Ops!",
          text: mensagem,
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.apresentacao {
  margin-top: 4rem;
  h1 {
    font-family: "Poppins-Bold";
    color: #256f0d;
  }
  p {
    text-align: justify;
  }
  .text-center {
    display: grid;
    text-align: center;
    margin: 0;
    .assina {
      display: grid;
    }
    .assinatura {
      color: rgb(0, 0, 181);
      margin-top: 1rem;
    }
  }
  .dados {
    display: grid;
  }
}
.acessar-form {
  background-color: #e6e6e6;
  margin-top: 1rem;
  form {
    text-align: center;
    display: grid;
    justify-content: center;
    .disp-flex {
      display: flex;
      align-items: center;
      text-align: center;
      margin: 0.5rem auto;
      flex-wrap: wrap;
      justify-content: space-around;
    }
    input[type="text"] {
      width: auto;
      width: calc(100% - 1rem);
      max-width: 20rem;
      font-size: 2rem;
      color: rgb(73, 73, 73);
      border: 1px solid rgb(169, 169, 169);
      padding: 0.5rem;
      text-align: center;
      border-radius: 0.2rem;
      margin: 0.5rem;
    }
    input[type="text"]:focus {
      outline: none;
      box-shadow: solid 0.15rem #ffa600;
    }
    button {
      background-color: transparent;
      border: none;
      border: solid 0.15rem #256f0d;
      height: 2.5em;
      border-radius: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0.5rem;
      transition: 0.2s;
      cursor: pointer;
      font-size: 1rem;
      padding: 0.2rem 1rem 0.2rem 2rem;
      font-family: "Poppins-SemiBold";
      text-decoration: none;
      color: #256f0d;
      svg {
        fill: #f7a311;
        margin-left: 1rem;
      }
    }
    button:hover {
      background-color: #256f0d;
      color: white;
    }
    button:disabled {
      background-color: grey;
      border: none;
      color: white;
      cursor: not-allowed;
      svg {
        fill: white;
      }
    }
    button:disabled:hover {
      background-color: grey;
      border: none;
      color: white;
      svg {
        fill: white;
      }
    }
  }
}
</style>
