import axios from "axios";
import axiosInstance from "../axiosInstance";

export default class FormularioIncentivosMunicipais {
  cancelTokens = {
    getFormularioIncentivosMunicipais: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getFormularioIncentivosMunicipais() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getFormularioIncentivosMunicipais = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/formulario_incentivos_municipais`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getFormularioIncentivosMunicipais = null;

    return data;
  }
}