<template>
  <div class="tela-formulario">
    <div class="menu" id="menu-formulario">
      <div class="centro">
        <img
          src="../../assets/images/logo-mapeamento.png"
          alt="Logo do Programa de Mapeamento de Oportunidades de Negócios"
          class="logo-mapeamento"
        />
        <img
          src="../../assets/images/logo-goias-color.png"
          alt="Logo Governo de Goiás"
          class="logo-gov"
        />
      </div>
    </div>

    <template v-if="!carregandoValores && !erroCarregarValores">
      <InformacoesMunicipaisCodigo
        v-if="etapa == 1"
        @codigo-foi-validado="mudarEtapa"
      />
      <Formulario
        v-if="etapa == 2"
        :categorias-industriais="categoriasIndustrais"
        :infraestruturas="infraestruturas"
        :codigo-municipio-mapeamento-oportunidade="
          codigoMunicipioMapeamentoOportunidade
        "
        :formulario-incentivos-municipais="formularioIncentivosMunicipais"
        :instituicoes-capacitacao="instituicoesCapacitacao"
        @salvou="etapa = 3"
      />
      <FormularioSucesso v-if="etapa == 3" />
    </template>
    <AppLoading
      class="disp-flex"
      v-if="carregandoValores && !erroCarregarValores"
    />
    <AppErro
      class="disp-flex-2"
      v-if="!carregandoValores && erroCarregarValores"
    />
  </div>
</template>

<script>
import AppErro from "../../components/AppErro";
import AppLoading from "../../components/AppLoading";
import InformacoesMunicipaisCodigo from "../../components/informacoes_municipais/InformacoesMunicipaisCodigo";
import Formulario from "../../components/informacoes_municipais/Formulario";
import FormularioSucesso from "../../components/informacoes_municipais/FormularioSucesso";

import FormularioIncentivosMunicipaisApi from "../../services/informacoes_municipais/FormularioIncentivosMunicipaisApi";
import FormCategoriasIndustriaisApi from "../../services/informacoes_municipais/FormCategoriasIndustriaisApi";
import InstituicoesCapacitacaoApi from "../../services/informacoes_municipais/InstituicoesCapacitacaoApi";
import InfraestruturasApi from "../../services/informacoes_municipais/InfraestruturasApi";

export default {
  name: "FormularioInformacoesMunicipais",
  components: {
    AppErro,
    AppLoading,
    InformacoesMunicipaisCodigo,
    Formulario,
    FormularioSucesso,
  },
  data() {
    return {
      etapa: 1,
      codigoMunicipioMapeamentoOportunidade: null,
      formularioIncentivosMunicipais: [],
      categoriasIndustrais: [],
      instituicoesCapacitacao: [],
      infraestruturas: [],
      carregandoValores: false,
      erroCarregarValores: false,
    };
  },
  created() {
    this.formularioIncentivosMunicipaisApi = new FormularioIncentivosMunicipaisApi();
    this.formCategoriasIndustriaisApi = new FormCategoriasIndustriaisApi();
    this.instituicoesCapacitacaoApi = new InstituicoesCapacitacaoApi();
    this.infraestruturasApi = new InfraestruturasApi();

    this.carregarValores();
  },
  methods: {
    mudarEtapa(codigoMunicipioMapeamentoOportunidade) {
      this.codigoMunicipioMapeamentoOportunidade = codigoMunicipioMapeamentoOportunidade;
      this.etapa = 2;
    },
    async carregarValores() {
      try {
        this.carregandoValores = true;
        this.erroCarregarValores = false;

        const [
          formularioIncentivosMunicipais,
          categoriasIndustrais,
          instituicoesCapacitacao,
          infraestruturas,
        ] = await Promise.all([
          this.formularioIncentivosMunicipaisApi.getFormularioIncentivosMunicipais(),
          this.formCategoriasIndustriaisApi.getCategoriasIndustriais(),
          this.instituicoesCapacitacaoApi.getInstituicoesCapacitacao(),
          this.infraestruturasApi.getInfraestruturas(),
        ]);

        this.formularioIncentivosMunicipais = formularioIncentivosMunicipais;
        this.categoriasIndustrais = categoriasIndustrais;
        this.instituicoesCapacitacao = instituicoesCapacitacao;
        this.infraestruturas = infraestruturas;

        this.carregandoValores = false;
      } catch (err) {
        console.log(err);

        this.erroCarregarValores = true;
        this.carregandoValores = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  letter-spacing: 0.02rem;
}
.tela-formulario {
  background-color: #ffffff;
}
.disp-flex {
  height: 100vh;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.disp-flex-2 {
  height: 100vh;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
}
.menu {
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100%;
  padding: 0.5rem 0rem 0;
  background-color: white;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.459);
  .centro {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    .logo-mapeamento {
      height: 2.5rem;
    }
    .logo-gov {
      height: 3rem;
      display: none;
    }
  }
}
@media screen and(min-width: '31.5rem') {
  .menu {
    .centro {
      justify-content: space-between;
      .logo-mapeamento {
        height: 3rem;
      }
      .logo-gov {
        display: block;
      }
    }
  }
}
</style>
