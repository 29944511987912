<template>
  <div class="item">
    <p>Qual o potencial econômico do município?</p>
    <div class="row3">
      <label
        class="check"
        v-for="categoria in categoriasIndustriais"
        :key="categoria.id"
      >
        <input type="checkbox" @change="toggle(categoria.id)" />
        <div class="box"></div>
        <span>{{ categoria.nome }}</span>
      </label>
      <div class="item-check">
        <label class="check">
          <input type="checkbox" @change="toggleOutros" />
          <div class="box"></div>
          <span>Outro </span>
        </label>
        <div class="item-input" v-if="outrosChecked">
          <label>Qual? <span class="red-text">(Obrigatório)</span></label>
          <div class="campo-input">
            <input
              class="effect-1"
              type="text"
            placeholder="Sua Resposta"
              required
              @input="(evt) => outrosAlterado(evt.target.value)"
            />
          <span class="focus-border"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const cloneDeep = require("lodash.clonedeep");

export default {
  name: "FormPotencialEconomico",
  model: {
    prop: "formularioPotenciaisEconomicos",
    event: "change",
  },
  props: {
    formularioPotenciaisEconomicos: {
      type: Array,
      default: () => [],
    },
    categoriasIndustriais: {
      type: Array,
      required: true,
    },
  },
  methods: {
    toggle(id) {
      const formularioPotenciaisEconomicos = cloneDeep(
        this.formularioPotenciaisEconomicos
      );

      const index = formularioPotenciaisEconomicos.findIndex(
        (item) => item.categoria_industrial_id == id
      );

      if (index == -1) {
        formularioPotenciaisEconomicos.push({
          categoria_industrial_id: id,
          formulario_informacao_municipio_id: null,
          outros: null,
        });
      } else {
        formularioPotenciaisEconomicos.splice(index, 1);
      }
      this.$emit("change", formularioPotenciaisEconomicos);
    },
    toggleOutros() {
      const formularioPotenciaisEconomicos = cloneDeep(
        this.formularioPotenciaisEconomicos
      );

      const index = formularioPotenciaisEconomicos.findIndex(
        (item) => item._outros
      );

      if (index == -1) {
        formularioPotenciaisEconomicos.push({
          categoria_industrial_id: null,
          formulario_informacao_municipio_id: null,
          outros: null,
          _outros: true,
        });
      } else {
        formularioPotenciaisEconomicos.splice(index, 1);
      }
      this.$emit("change", formularioPotenciaisEconomicos);
    },
    outrosAlterado(value) {
      const formularioPotenciaisEconomicos = cloneDeep(
        this.formularioPotenciaisEconomicos
      );

      const index = formularioPotenciaisEconomicos.findIndex(
        (item) => item._outros
      );

      if (index != -1) {
        formularioPotenciaisEconomicos[index].outros = value;
        this.$emit("change", formularioPotenciaisEconomicos);
      }
    },
  },
  computed: {
    outrosChecked() {
      return this.formularioPotenciaisEconomicos.some((item) => item._outros);
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  display: grid;
  margin: 1rem 0;
  background-color: white;
  padding: 1rem;
  border-radius: 0.2rem;
  .disp-flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    button {
      border: solid 0.15rem #256f0d;
      background-color: transparent;
      font-family: "Poppins-Medium";
      color: #256f0d;
      font-size: 1rem;
      border-radius: 0.2rem;
      margin: 0 auto 0.5rem;
      transition: 0.2s;
      padding: 0.2rem 1rem;
      cursor: pointer;
    }
    button:hover {
      background-color: #256f0d;
      color: white;
    }
  }
  .red-text {
    color: red;
  }
  .grupo {
    background-color: #205f0b17;
    padding: 0.5rem;
    margin: 0.5rem 0;
    .item-2 {
      margin: 0.5rem 0;
      border-radius: 0.3rem;
    }
    input {
      width: 100%;
      width: calc(100% - 0.5rem);
    }
  }
  .campo-input {
    position: relative;
    :focus {
      outline: none;
    }
    input[type="text"] {
      color: black;
      width: 100%;
      box-sizing: border-box;
      letter-spacing: 1px;
      height: 1.5rem;
      font-size: 1rem;
      // width: calc(100% - 0.5rem);
      border: 0;
      border-bottom: solid 0.1rem rgb(105, 105, 105);
    }

    .effect-1 {
      border: 0;
      background-color: transparent;
      padding: 7px 0;
      border-bottom: 1px solid #ccc;
    }

    .effect-1 ~ .focus-border {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 2px;
      background-color: #256f0d;
      transition: 0.4s;
    }
    .effect-1:focus ~ .focus-border {
      width: 100%;
      transition: 0.4s;
      outline: none;
    }
  }
  p {
    margin: 0 0 0.5rem;
    font-family: "Poppins-Medium";
    span {
      color: red;
    }
  }
  :focus {
    outline: none;
  }
  .check {
    display: flex;
    margin: 0.1rem 0;
    align-items: flex-start;
    span {
      font-family: "Poppins";
      .effect-1 {
        padding-left: 0.5rem;
        width: calc(100% - 0.5rem);
      }
      .effect-1,
      .focus-border {
        margin-left: 0.5rem;
      }
    }

    input[type="checkbox"] {
      display: none;
      &:checked + .box {
        background-color: #f4fff4;

        &:after {
          top: 0;
        }
      }
    }

    .box {
      min-width: 1.2rem;
      height: 1.2rem;
      transition: all 1.1s cubic-bezier(0.19, 1, 0.22, 1);
      border: 2px solid transparent;
      border-radius: 0.2rem;
      background-color: white;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      border: 0.1rem solid #888888;
      margin: 0.1rem 0.5rem 0 0;
      &:after {
        width: 50%;
        height: 20%;
        content: "";
        position: absolute;
        border-left: 3px solid;
        border-bottom: 3px solid;
        border-color: #256f0d;
        transform: rotate(-45deg) translate3d(0, 0, 0);
        transform-origin: center center;
        transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1);
        left: 0;
        right: 0;
        top: 200%;
        bottom: 5%;
        margin: auto;
      }
    }
  }
  input[type="radio"] {
    appearance: none;
    margin: 0 0.5rem;
    width: 12px;
    height: 12px;
    background: #eeeeee;
    box-shadow: inset 0 0 0 0.15em white, 0 0 0 0.15em;
    border-radius: 50%;
    transition: 0.2s;
    cursor: pointer;
    color: #3f4042;

    &:checked {
      background: #3f4042;
      box-shadow: inset 0 0 0 0.2em white, 0 0 0 0.15em;
    }

    &:checked {
      background: #256f0d;
      box-shadow: inset 0 0 0 0.2em white, 0 0 0 0.15em #256f0d;
    }

    &:focus {
      outline: 0;
    }
  }
}

@media screen and(min-width: '50rem') {
  .item {
    margin-bottom: 0rem !important;
  }
  .row2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }
  .item-check {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: start;
    align-items: baseline;
    gap: 1.5rem;
  }
  .m-bottom {
    margin-bottom: 0 !important;
  }
}
</style>
