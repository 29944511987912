export default function(route) {
  const title = "Invista em Goiás";
  const description =
    "tudo sobre infraestrutura, logística, pólos industriais, linhas de crédito, fomento, incentivo, desempenho da indústria, serviços e agro.";
  return [
    {
      vmid: "description",
      name: "description",
      property: "description",
      content: description,
    },
    {
      vmid: "og:description",
      property: "og:description",
      content: description,
    },

    {
      vmid: "og:url",
      property: "og:url",
      content: `${process.env.VUE_APP_BASE_URL}${route.fullPath}`,
    },
    {
      vmid: "og:type",
      property: "og:type",
      content: "article",
    },
    {
      vmid: "twitter:site",
      name: "twitter:site",
      content: `${process.env.VUE_APP_BASE_URL}${route.fullPath}`,
    },
    {
      vmid: "twitter:description",
      name: "twitter:description",
      property: "twitter:description",
      content: description,
    },
    {
      vmid: "twitter:title",
      name: "twitter:title",
      property: "twitter:title",
      content: `${title} - Casa Civil do Estado de Goiás`,
    },

    {
      vmid: "og:image",
      property: "og:image",
      content: process.env.VUE_APP_BASE_URL + "/logo-investe.png",
    },
    {
      vmid: "og:image:secure_url",
      property: "og:image:secure_url",
      content: process.env.VUE_APP_BASE_URL + "/logo-investe.png",
    },
    {
      vmid: "og:image:type",
      property: "og:image:type",
      content: "image/png",
    },
    {
      vmid: "twitter:image",
      name: "twitter:image",
      property: "twitter:image",
      content: process.env.VUE_APP_BASE_URL + "/logo-investe.png",
    },
    {
      vmid: "og:image:width",
      property: "og:image:width",
      content: "1201",
    },
    {
      vmid: "og:image:height",
      property: "og:image:height",
      content: "631",
    },
  ];
}
