<template>
  <div>
    <TheMenu v-if="exibirMenu" />
    <router-view></router-view>
    <TheFooter v-if="exibirMenu" />
    <ThePoliticaPrivacidade v-if="exibirMenu" />
  </div>
</template>

<script>
import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";
import VueLayers from "vuelayers";
import "vuelayers/lib/style.css"; // needs css-loader

import VueSweetalert2 from "vue-sweetalert2";

import "sweetalert2/dist/sweetalert2.min.css";

import TheMenu from "./components/TheMenu";
import TheFooter from "./components/TheFooter";
import ThePoliticaPrivacidade from "./components/ThePoliticaPrivacidade";

import { FORMULARIO_INFORMACOES_MUNICIPAIS } from "./router/routeNames";

Vue.use(VueRouter);
Vue.use(VueMeta);
Vue.use(VueLayers);
Vue.use(VueSweetalert2);

export default {
  name: "App",
  metaInfo() {
    return {
      title: "Invista em Goiás",
      titleTemplate: "%s - Governo de Goiás",
    };
  },
  data() {
    return {
      rotasSemMenu: [FORMULARIO_INFORMACOES_MUNICIPAIS],
    };
  },
  components: {
    TheMenu,
    TheFooter,
    ThePoliticaPrivacidade,
  },
  computed: {
    exibirMenu() {
      return !this.rotasSemMenu.includes(this.$route.name);
    },
  },
};
</script>

<style></style>
