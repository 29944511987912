<template>
  <div class="centro">
    <h1>Oops...</h1>
    <NumberErro />
    <h1>{{ $t("nao-encontrado.titulo") }}</h1>
    <p>{{ $t("nao-encontrado.mensagem") }}</p>
  </div>
</template>

<script>
import NumberErro from "../assets/images/number-erro.svg";
import metadados from "../helpers/metadados";

export default {
  name: "NaoEncontrado",
  metaInfo() {
    return {
      meta: [...metadados(this.$route)],
    };
  },
  components: {
    NumberErro,
  },
};
</script>

<style lang="scss" scoped>
.centro {
  margin-top: 7rem;
  text-align: center;
  svg {
    height: 5rem;
    width: 100%;
    fill: #153d08;
  }
  h1 {
    margin: 0.5rem;
    color: #f7a311;
    font-family: "Poppins-SemiBold";
    line-height: 2.5rem;
  }
  p {
    color: black;
  }
}
.alto-contraste {
  .centro {
    svg {
      fill: #f7a311;
    }
    p {
      color: white;
    }
    h1 {
      color: #256f0d;
    }
  }
}
@media screen and (min-width: "64rem") {
  .centro {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}
</style>
