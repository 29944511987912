<template>
  <div class="row1">
    <div class="item">
      <p>Possui incentivos municipais?</p>
      <div class="row2">
        <div
          class="item-check m-bottom"
          v-for="formularioIncentivoMunicipal in formularioIncentivosMunicipais"
          :key="formularioIncentivoMunicipal.id"
        >
          <label class="check">
            <input
              type="checkbox"
              :ref="formularioIncentivoMunicipal.id"
              :value="formularioIncentivoMunicipal.id"
              @change="
                incentivoMunicipalSelecionado(formularioIncentivoMunicipal.id)
              "
            />
            <div class="box"></div>
            <span>{{ formularioIncentivoMunicipal.nome }}</span>
          </label>

          <div
            class="item-input"
            v-if="exibirCampos[formularioIncentivoMunicipal.id]"
          >
            <label>Legislação Referente:</label>
            <div class="campo-input">
              <input
                class="effect-1"
                type="text"
                placeholder="Sua Resposta"
                @input="
                  (evt) =>
                    incentivoMunicipalLegislacaoAlterou(
                      formularioIncentivoMunicipal.id,
                      evt.target.value
                    )
                "
              />
              <span class="focus-border"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="item-check disp-flex-desk">
        <label class="check">
          <input type="checkbox" @change="outrosSelecionado" />
          <div class="box"></div>
          <span>Outros</span>
        </label>
        <div class="item-input" v-if="exibirCampos['outros']">
          <label>Qual? <span class="red-text">(Obrigatório)</span></label>
          <div class="campo-input">
            <input
              class="effect-1"
              type="text"
              placeholder="Sua Resposta"
              @input="(evt) => outroAlterado('outros', evt.target.value)"
              required
            />
            <span class="focus-border"></span>
          </div>
        </div>
        <div class="item-input" v-if="exibirCampos['outros']">
          <label>Legislação Referente:</label>
          <div class="campo-input">
            <input
              class="effect-1"
              type="text"
              placeholder="Sua Resposta"
              @input="(evt) => outroAlterado('legislacao', evt.target.value)"
            />
            <span class="focus-border"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const cloneDeep = require("lodash.clonedeep");

export default {
  name: "FormIncentivosMunicipais",
  model: {
    prop: "incentivosMunicipais",
    event: "change",
  },
  props: {
    incentivosMunicipais: {
      type: Array,
    },
    formularioIncentivosMunicipais: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    incentivoMunicipalSelecionado(id) {
      const index = this.incentivosMunicipais.findIndex(
        (item) => item.formulario_incentivo_municipal_id == id
      );
      let incentivosMunicipais = cloneDeep(this.incentivosMunicipais);

      if (index == -1) {
        incentivosMunicipais.push({
          formulario_incentivo_municipal_id: id,
          outros: null,
          legislacao: null,
        });
      } else {
        incentivosMunicipais.splice(index, 1);
      }

      this.$emit("change", incentivosMunicipais);
    },
    incentivoMunicipalLegislacaoAlterou(id, value) {
      const index = this.incentivosMunicipais.findIndex(
        (item) => item.formulario_incentivo_municipal_id == id
      );
      if (index != -1) {
        let incentivosMunicipais = cloneDeep(this.incentivosMunicipais);

        incentivosMunicipais[index].legislacao = value;

        this.$emit("change", incentivosMunicipais);
      }
    },
    outrosSelecionado() {
      const index = this.incentivosMunicipais.findIndex((item) => item._outros);
      let incentivosMunicipais = cloneDeep(this.incentivosMunicipais);
      if (index == -1) {
        incentivosMunicipais.push({
          _outros: true,
          outros: null,
          legislacao: null,
        });

        this.$emit("change", incentivosMunicipais);
      } else {
        incentivosMunicipais.splice(index, 1);

        this.$emit("change", incentivosMunicipais);
      }
    },
    outroAlterado(key, value) {
      const index = this.incentivosMunicipais.findIndex((item) => item._outros);

      if (index != -1) {
        let incentivosMunicipais = cloneDeep(this.incentivosMunicipais);

        incentivosMunicipais[index][key] = value;

        this.$emit("change", incentivosMunicipais);
      }
    },
  },
  computed: {
    exibirCampos() {
      const campos = this.formularioIncentivosMunicipais.reduce((acc, item) => {
        acc[item.id] = this.incentivosMunicipais.some(
          (incentivo) => incentivo.formulario_incentivo_municipal_id == item.id
        );

        return acc;
      }, {});

      campos["outros"] = this.incentivosMunicipais.some(
        (incentivo) => incentivo._outros
      );

      return campos;
    },
  },
};
</script>

<style lang="scss" scoped>
.m-bottom {
  margin-bottom: 1rem;
}
.item {
  display: grid;
  margin: 1rem 0;
  background-color: #ececec;
  padding: 1rem;
  border-radius: 0.2rem;
  .item-check {
    background-color: white;
    padding: 1rem;
    border-radius: 0.3rem;
    .item-input {
      margin-top: 1rem;
    }
  }
  .grupo {
    background-color: #205f0b17;
    padding: 0.5rem;
    margin: 0.5rem 0;
    .item-2 {
      margin: 0.5rem 0;
      border-radius: 0.3rem;
    }
    input {
      width: 100%;
      width: calc(100% - 0.5rem);
    }
  }
  .campo-input {
    position: relative;
    :focus {
      outline: none;
    }
  }
  p {
    margin: 0 0 0.5rem;
    font-family: "Poppins-Medium";
    span {
      color: red;
    }
  }
  .red-text {
    color: red;
  }

  :focus {
    outline: none;
  }
}

.campo-input {
  position: relative;
  :focus {
    outline: none;
  }
  input[type="text"] {
    color: black;
    width: 100%;
    box-sizing: border-box;
    letter-spacing: 1px;
    height: 1.5rem;
    font-size: 1rem;
    // width: calc(100% - 0.5rem);
    border: 0;
    border-bottom: solid 0.1rem rgb(105, 105, 105);
  }

  .effect-1 {
    border: 0;
    background-color: transparent;
    padding: 7px 0;
    border-bottom: 1px solid #ccc;
  }

  .effect-1 ~ .focus-border {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #256f0d;
    transition: 0.4s;
  }
  .effect-1:focus ~ .focus-border {
    width: 100%;
    transition: 0.4s;
    outline: none;
  }
}

.check {
  display: flex;
  margin: 0.1rem 0;
  align-items: flex-start;
  span {
    display: flex;
    font-family: "Poppins";
    .effect-1 {
      padding-left: 0.5rem;
      width: calc(100% - 0.5rem);
    }
    .effect-1,
    .focus-border {
      margin-left: 0.5rem;
    }
  }

  input[type="checkbox"] {
    display: none;
    &:checked + .box {
      background-color: #f4fff4;

      &:after {
        top: 0;
      }
    }
  }

  .box {
    min-width: 1.2rem;
    height: 1.2rem;
    transition: all 1.1s cubic-bezier(0.19, 1, 0.22, 1);
    border: 2px solid transparent;
    border-radius: 0.2rem;
    background-color: white;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border: 0.1rem solid #888888;
    margin: 0.1rem 0.5rem 0 0;
    &:after {
      width: 50%;
      height: 20%;
      content: "";
      position: absolute;
      border-left: 3px solid;
      border-bottom: 3px solid;
      border-color: #256f0d;
      transform: rotate(-45deg) translate3d(0, 0, 0);
      transform-origin: center center;
      transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1);
      left: 0;
      right: 0;
      top: 200%;
      bottom: 5%;
      margin: auto;
    }
  }
}

@media screen and(min-width: '50rem') {
  .item {
    margin: 0;
  }
  .row2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-bottom: 0rem;
  }
  .item-check {
    display: grid;
    padding: 0.5rem;
    height: 3.5rem;
    grid-template-columns: auto auto;
    justify-content: start;
    align-items: center;
    gap: 1.5rem;
    .item-input {
      margin-top: 0 !important;
    }
  }
  .disp-flex-desk {
    display: flex;
  }
}
</style>
