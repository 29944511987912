<template>
  <div class="item">
    <p>Infraestrutura:</p>
    <div class="row3">
      <label class="check" v-for="infra in infraestruturas" :key="infra.id">
        <input type="checkbox" @change="toggle(infra.id)" />
        <div class="box"></div>
        <span>{{ infra.nome }}</span>
      </label>
      <div class="item-check">
        <label class="check">
          <input type="checkbox" @change="toggleOutros" />
          <div class="box"></div>
          <span>Outro </span>
        </label>
        <div class="item-input" v-if="outrosChecked">
          <label>Qual? <span class="red-text"> (Obrigatório)</span></label>
          <div class="campo-input">
            <input
              class="effect-1"
              type="text"
            placeholder="Sua Resposta"
              required
              @input="(evt) => outrosAlterado(evt.target.value)"
            />
            <span class="focus-border"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const cloneDeep = require("lodash.clonedeep");

export default {
  name: "FormInfraestruturas",
  model: {
    prop: "formularioMunicipiosInfraestruturas",
    event: "change",
  },
  props: {
    infraestruturas: {
      type: Array,
      required: true,
    },
    formularioMunicipiosInfraestruturas: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    toggle(id) {
      const formularioMunicipiosInfraestruturas = cloneDeep(
        this.formularioMunicipiosInfraestruturas
      );

      const index = formularioMunicipiosInfraestruturas.findIndex(
        (item) => item.infraestrutura_id == id
      );

      if (index == -1) {
        formularioMunicipiosInfraestruturas.push({
          infraestrutura_id: id,
          formulario_informacao_municipio_id: null,
          outros: null,
        });
      } else {
        formularioMunicipiosInfraestruturas.splice(index, 1);
      }
      this.$emit("change", formularioMunicipiosInfraestruturas);
    },
    toggleOutros() {
      const formularioMunicipiosInfraestruturas = cloneDeep(
        this.formularioMunicipiosInfraestruturas
      );

      const index = formularioMunicipiosInfraestruturas.findIndex(
        (item) => item._outros
      );

      if (index == -1) {
        formularioMunicipiosInfraestruturas.push({
          infraestrutura_id: null,
          formulario_informacao_municipio_id: null,
          outros: null,
          _outros: true,
        });
      } else {
        formularioMunicipiosInfraestruturas.splice(index, 1);
      }
      this.$emit("change", formularioMunicipiosInfraestruturas);
    },
    outrosAlterado(value) {
      const formularioMunicipiosInfraestruturas = cloneDeep(
        this.formularioMunicipiosInfraestruturas
      );

      const index = formularioMunicipiosInfraestruturas.findIndex(
        (item) => item._outros
      );

      if (index != -1) {
        formularioMunicipiosInfraestruturas[index].outros = value;
        this.$emit("change", formularioMunicipiosInfraestruturas);
      }
    },
  },
  computed: {
    outrosChecked() {
      return this.formularioMunicipiosInfraestruturas.some(
        (item) => item._outros
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  display: grid;
  margin: 1rem 0 !important;
  padding: 1rem;
  border-radius: 0.2rem;
  .campo-input {
    position: relative;
    :focus {
      outline: none;
    }
    input[type="text"],
    input[type="number"] {
      width: 100%;
      box-sizing: border-box;
      letter-spacing: 1px;
      height: 1.5rem;
      font-size: 1rem;
      // width: calc(100% - 0.5rem);
      border: 0;
      border-bottom: solid 0.1rem rgb(105, 105, 105);
    }

    .effect-1 {
      border: 0;
      background-color: transparent;
      padding: 7px 0;
      border-bottom: 1px solid #ccc;
    }

    .effect-1 ~ .focus-border {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 2px;
      background-color: #256f0d;
      transition: 0.4s;
    }
    .effect-1:focus ~ .focus-border {
      width: 100%;
      transition: 0.4s;
      outline: none;
    }
  }
  p {
    margin: 0 0 0.5rem;
    font-family: "Poppins-Medium";
    span {
      color: red;
    }
  }
  .red-text{
    color: red;
  }
  :focus {
    outline: none;
  }
  .check {
    display: flex;
    margin: 0.1rem 0;
    align-items: flex-start;
    span {
      display: flex;
      font-family: "Poppins";
      .effect-1 {
        padding-left: 0.5rem;
        width: calc(100% - 0.5rem);
      }
      .effect-1,
      .focus-border {
        margin-left: 0.5rem;
      }
    }

    input[type="checkbox"] {
      display: none;
      &:checked + .box {
        background-color: #f4fff4;
        &:after {
          top: 0;
        }
      }
    }

    .box {
      min-width: 1.2rem;
      height: 1.2rem;
      transition: all 1.1s cubic-bezier(0.19, 1, 0.22, 1);
      border: 2px solid transparent;
      border-radius: 0.2rem;
      background-color: white;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      border: 0.1rem solid #888888;
      margin: 0.1rem 0.5rem 0 0;
      &:after {
        width: 50%;
        height: 20%;
        content: "";
        position: absolute;
        border-left: 3px solid;
        border-bottom: 3px solid;
        border-color: #256f0d;
        transform: rotate(-45deg) translate3d(0, 0, 0);
        transform-origin: center center;
        transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1);
        left: 0;
        right: 0;
        top: 200%;
        bottom: 5%;
        margin: auto;
      }
    }
  }
}

@media screen and(min-width: '31.5rem') {
  .row2-3 {
    display: grid;
    grid-template-columns: 4fr 1fr;
    gap: 1rem;
  }
}

@media screen and(min-width: '50rem') {
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }
  .item-check {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: start;
    align-items: center;
    gap: 1.5rem;
  }
}
</style>
