<template>
  <div class="formulario centro">
    <h1>Solicitação de Informações Municipais</h1>
    <p>
      Coleta de dados para análise de potencialidades dos municípios do Estado
      de Goiás.
    </p>
    <div class="item">
      <span
        ><b>Município:</b>
        {{ codigoMunicipioMapeamentoOportunidade.municipio.nome }}</span
      >
    </div>
    <form @submit="salvar">
      <div class="row2">
        <div class="item">
          <p>
            Nome do Responsável pelo preenchimento: <span>(Obrigatório)</span>
          </p>
          <div class="campo-input">
            <input
              class="effect-1"
              type="text"
              placeholder="Sua Resposta"
              required
              v-model="formularioInformacaoMunicipal.nome_pessoa_resposta"
            />
            <span class="focus-border"></span>
          </div>
        </div>

        <div class="item">
          <p>E-mail: <span>(Obrigatório)</span></p>
          <div class="campo-input">
            <input
              class="effect-1"
              type="text"
              placeholder="Sua Resposta"
              required
              v-model="formularioInformacaoMunicipal.email_pessoa_resposta"
            />
            <span class="focus-border"></span>
          </div>
        </div>
      </div>
      <FormIncentivosMunicipais
        :formulario-incentivos-municipais="formularioIncentivosMunicipais"
        v-model="formularioInformacaoMunicipal.incentivos_municipios"
      />
      <div class="row2">
        <div class="item">
          <p>Possui Distrito Industrial? <span>(Obrigatório)</span></p>
          <div class="row2">
            <label
              ><input
                class="medium"
                type="radio"
                name="distrito_industrial"
                :value="true"
                v-model="
                  formularioInformacaoMunicipal.possui_distrito_industrial
                "
              />
              Sim</label
            >
            <label
              ><input
                class="medium"
                type="radio"
                name="distrito_industrial"
                :value="false"
                v-model="
                  formularioInformacaoMunicipal.possui_distrito_industrial
                "
              />
              Não</label
            >
          </div>
        </div>

        <div
          class="item"
          v-if="formularioInformacaoMunicipal.possui_distrito_industrial"
        >
          <p>
            Se possui, qual o tipo do Distrito Industrial?
            <span>(Obrigatório)</span>
          </p>
          <div class="row2">
            <label
              ><input
                class="medium"
                type="radio"
                name="tipo_distrito_industrial"
                v-model="formularioInformacaoMunicipal.tipo_distrito_industrial"
                value="estadual"
                required
              />
              Estadual</label
            >
            <label
              ><input
                class="medium"
                type="radio"
                name="tipo_distrito_industrial"
                value="municipal"
                required
                v-model="formularioInformacaoMunicipal.tipo_distrito_industrial"
              />
              Municipal</label
            >
          </div>
        </div>
      </div>
      <FormAreasDisponiveis
        v-model="formularioInformacaoMunicipal.formulario_areas_disponiveis"
      />
      <FormPotencialEconomico
        :categorias-industriais="categoriasIndustriais"
        v-model="
          formularioInformacaoMunicipal.formulario_municipios_potenciais_economicos
        "
      />

      <div class="row2">
        <div class="item">
          <p>
            Existe potencial turístico no município, qual?
            <span>(Obrigatório)</span>
          </p>
          <div class="campo-input">
            <input
              class="effect-1"
              type="text"
              placeholder="Sua Resposta"
              v-model="formularioInformacaoMunicipal.potencial_turistico"
              required
            />
            <span class="focus-border"></span>
          </div>
        </div>

        <div class="item">
          <p>O município emite licença ambiental? <span>(Obrigatório)</span></p>
          <div class="row2">
            <label
              ><input
                class="medium"
                type="radio"
                name="Distrito Industrial"
                :value="true"
                v-model="formularioInformacaoMunicipal.emite_licenca_ambiental"
              />
              Sim</label
            >
            <label
              ><input
                class="medium"
                type="radio"
                name="Distrito Industrial"
                :value="false"
                v-model="formularioInformacaoMunicipal.emite_licenca_ambiental"
              />
              Não</label
            >
          </div>
        </div>
      </div>

      <FormInstituicoesCapacitacao
        :instituicoes-capacitacao="instituicoesCapacitacao"
        v-model="
          formularioInformacaoMunicipal.formulario_instituicoes_capacitacao_municipios
        "
      />

      <FormInfraestruturas
        :infraestruturas="infraestruturas"
        v-model="
          formularioInformacaoMunicipal.formulario_municipios_infraestruturas
        "
      />
      <div class="item">
        <p>
          Informações adicionais relevantes (informações da prefeitura):
          <span>(Obrigatório)</span>
        </p>
        <div class="campo-input">
          <input
            class="effect-1"
            type="text"
            placeholder="Sua Resposta"
            v-model="formularioInformacaoMunicipal.informacoes_adicionais"
          />
          <span class="focus-border"></span>
        </div>
      </div>

      <div class="botao-enviar">
        <button v-if="!salvando"><IconSend />Enviar Formulário</button>
      </div>
      <div class="botao-enviar-salvando">
        <AppLoading v-if="salvando" size="small" texto="" />
      </div>
    </form>
  </div>
</template>

<script>
import AppLoading from "../AppLoading";
import IconSend from "../../assets/images/icon-send.svg";
import FormAreasDisponiveis from "./FormAreasDisponiveis";
import FormIncentivosMunicipais from "./FormIncentivosMunicipais";
import FormInstituicoesCapacitacao from "./FormInstituicoesCapacitacao";
import FormInfraestruturas from "./FormInfraestruturas";
import FormPotencialEconomico from "./FormPotencialEconomico";

import FormularioInformacoesMunicipaisApi from "../../services/informacoes_municipais/FormularioInformacoesMunicipaisApi";

const get = require("lodash.get");

export default {
  name: "Formulario",
  components: {
    IconSend,
    FormAreasDisponiveis,
    FormIncentivosMunicipais,
    FormInstituicoesCapacitacao,
    FormInfraestruturas,
    FormPotencialEconomico,
    AppLoading,
  },
  props: {
    codigoMunicipioMapeamentoOportunidade: {
      type: Object,
      required: true,
    },
    formularioIncentivosMunicipais: {
      type: Array,
      required: true,
    },
    categoriasIndustriais: {
      type: Array,
      required: true,
    },
    infraestruturas: {
      type: Array,
      required: true,
    },
    instituicoesCapacitacao: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      formularioInformacaoMunicipal: {
        possui_distrito_industrial: false,
        tipo_distrito_industrial: null,
        emite_licenca_ambiental: false,
        potencial_turistico: null,
        informacoes_adicionais: null,
        status: null,
        municipio_id: this.codigoMunicipioMapeamentoOportunidade.municipio_id,
        email_pessoa_resposta: null,
        nome_pessoa_resposta: null,
        incentivos_municipios: [],
        formulario_areas_disponiveis: [],
        formulario_municipios_potenciais_economicos: [],
        formulario_municipios_infraestruturas: [],
        formulario_instituicoes_capacitacao_municipios: [],
      },
      salvando: false,
    };
  },
  created() {
    this.formularioInformacaoMunicipalApi = new FormularioInformacoesMunicipaisApi();
  },
  methods: {
    async salvar(e) {
      e.preventDefault();

      try {
        this.salvando = true;

        const attributes = {
          ...this.formularioInformacaoMunicipal,
          formulario_areas_disponiveis_attributes: this.formularioInformacaoMunicipal.formulario_areas_disponiveis.map(
            (area) => ({
              ...area,
              area_m2: area.area_m2.replace(/[^0-9]/g, ""),
            })
          ),
          formulario_municipios_infraestruturas_attributes: this
            .formularioInformacaoMunicipal
            .formulario_municipios_infraestruturas,
          formulario_municipios_potenciais_economicos_attributes: this
            .formularioInformacaoMunicipal
            .formulario_municipios_potenciais_economicos,
          formulario_instituicoes_capacitacao_municipios_attributes: this
            .formularioInformacaoMunicipal
            .formulario_instituicoes_capacitacao_municipios,
          codigo_municipio_mapeamento_oportunidade_id: this
            .codigoMunicipioMapeamentoOportunidade.id,
          incentivos_municipios_attributes: this.formularioInformacaoMunicipal
            .incentivos_municipios,
        };

        await this.formularioInformacaoMunicipalApi.salvarFormularioInformacaoMunicipal(
          attributes
        );

        this.salvando = false;
        this.$emit("salvou");
      } catch (err) {
        console.log(err, err.response);
        this.salvando = false;

        let mensagem = get(
          err,
          "response.data",
          "Houve um erro ao salvar os dados. Por favor recarregue a página e tente novamente"
        );

        if (Array.isArray(mensagem)) {
          mensagem = mensagem.join(", ");
        } else if (
          typeof mensagem === "object" &&
          mensagem !== null &&
          mensagem.mensagem
        ) {
          mensagem = mensagem.mensagem;
        }

        this.verificandoCodigo = false;

        this.$swal({
          title: "Ops!",
          text: mensagem,
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.formulario {
  margin-top: 3.5rem;
  h1 {
    font-family: "Poppins-Bold";
    color: #1c5808;
    line-height: 2.5rem;
  }
  .text-red {
    color: red;
    margin: 0;
  }
  .m-bottom {
    margin-bottom: 1rem;
  }
  .row2{
    display: grid;
  }
  .item {
    display: grid;
    margin: 1rem 0;
    background-color: #ececec;
    padding: 1rem;
    border-radius: 0.2rem;
    .disp-flex {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      button {
        border: solid 0.15rem #256f0d;
        background-color: transparent;
        font-family: "Poppins-Medium";
        color: #256f0d;
        font-size: 1rem;
        border-radius: 0.2rem;
        margin: 0 auto 0.5rem;
        transition: 0.2s;
        padding: 0.2rem 1rem;
        cursor: pointer;
      }
      button:hover {
        background-color: #256f0d;
        color: white;
      }
    }
    .item-check {
      background-color: #205f0b17;
      padding: 0.5rem;
      border-radius: 0.3rem;
    }
    .grupo {
      background-color: #205f0b17;
      padding: 0.5rem;
      margin: 0.5rem 0;
      .item-2 {
        margin: 0.5rem 0;
        border-radius: 0.3rem;
      }
      input {
        width: 100%;
        width: calc(100% - 0.5rem);
      }
    }
    .campo-input {
      position: relative;
      :focus {
        outline: none;
      }
      input[type="text"] {
        color: black;
        background-color: transparent;
        width: 100%;
        box-sizing: border-box;
        letter-spacing: 1px;
        height: 1.5rem;
        font-size: 1rem;
        border: 0;
        border-bottom: solid 0.1rem rgb(105, 105, 105);
      }

      .effect-1 {
        border: 0;
        background-color: transparent;
        padding: 7px 0;
        border-bottom: 1px solid #ccc;
      }

      .effect-1 ~ .focus-border {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px;
        background-color: #256f0d;
        transition: 0.4s;
      }
      .effect-1:focus ~ .focus-border {
        width: 100%;
        transition: 0.4s;
        outline: none;
      }
    }
    p {
      margin: 0 0 0.5rem;
      font-family: "Poppins-Medium";
      span {
        color: red;
      }
    }
    .check {
      display: flex;
      margin: 0.1rem 0;
      align-items: flex-start;
      span {
        display: flex;
        font-family: "Poppins";
        .effect-1 {
          padding-left: 0.5rem;
          width: calc(100% - 0.5rem);
        }
        .effect-1,
        .focus-border {
          margin-left: 0.5rem;
        }
      }

      input[type="checkbox"] {
        display: none;
        &:checked + .box {
          background-color: #f4fff4;

          &:after {
            top: 0;
          }
        }
      }

      .box {
        min-width: 1.2rem;
        height: 1.2rem;
        transition: all 1.1s cubic-bezier(0.19, 1, 0.22, 1);
        border: 2px solid transparent;
        border-radius: 0.2rem;
        background-color: white;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        border: 0.1rem solid #888888;
        margin: 0.1rem 0.5rem 0 0;
        &:after {
          width: 50%;
          height: 20%;
          content: "";
          position: absolute;
          border-left: 3px solid;
          border-bottom: 3px solid;
          border-color: #256f0d;
          transform: rotate(-45deg) translate3d(0, 0, 0);
          transform-origin: center center;
          transition: all 0.2s cubic-bezier(0.19, 1, 0.22, 1);
          left: 0;
          right: 0;
          top: 200%;
          bottom: 5%;
          margin: auto;
        }
      }
    }
    input[type="radio"] {
      appearance: none;
      margin: 0 0.5rem;
      width: 12px;
      height: 12px;
      background: #eeeeee;
      box-shadow: inset 0 0 0 0.15em white, 0 0 0 0.15em;
      border-radius: 50%;
      transition: 0.2s;
      cursor: pointer;
      color: #3f4042;

      &:checked {
        background: #3f4042;
        box-shadow: inset 0 0 0 0.2em white, 0 0 0 0.15em;
      }

      &:checked {
        background: #256f0d;
        box-shadow: inset 0 0 0 0.2em white, 0 0 0 0.15em #256f0d;
      }

      &:focus {
        outline: 0;
      }
    }
  }
  .botao-enviar-salvando {
    margin: 2rem auto 5rem;
  }
  .botao-enviar {
    text-align: center;
    button {
      font-size: 1rem;
      color: #256f0d;
      border: #256f0d solid 0.15rem;
      background-color: transparent;
      font-family: "Poppins-Medium";
      border-radius: 2rem;
      padding: 0.2rem 2rem 0.2rem 2rem;
      height: 3rem;
      transition: 0.2s;
      cursor: pointer;
      margin: 2rem auto 5rem;
      display: flex;
      align-items: center;
      svg {
        height: 1.5rem;
        margin-right: 1rem;
        fill: #d18400;
      }
    }
    button:hover {
      background-color: #256f0d;
      color: white;
    }
  }
}

@media screen and(min-width: '31.5rem') {
  .formulario {
    margin-top: 4rem;
  }
  .row1 {
    .item {
      .disp-flex {
        button {
          margin: 0 0.5rem 0.5rem;
        }
      }
    }
  }
  .row2-3 {
    display: grid;
    grid-template-columns: 4fr 1fr;
    gap: 1rem;
  }
}

@media screen and(min-width: '50rem') {
  .row2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  .row3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }
  .item-check {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: start;
    align-items: center;
    gap: 1.5rem;
  }
}
</style>
