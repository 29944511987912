<template>
  <a
    class="bt-scroll"
    title="Ir ao topo da página"
    @click="scrollToTheTop"
    v-if="exibirBotao"
  >
    <SetaTopo />
  </a>
</template>

<script>
import SetaTopo from "../assets/images/icon-seta-top.svg";

export default {
  name: "AppBotaoScroll",
  props: {
    elementoReferenciaId: {
      //elemento referencia para exibir o elemento
      type: String,
      required: true,
    },
  },
  components: {
    SetaTopo,
  },
  data() {
    return {
      exibirBotao: false,
    };
  },
  mounted() {
    document.addEventListener("scroll", this.scrollListener, {
      passive: true,
    });
    this.scrollListener();
  },
  unmounted() {
    document.removeEventListener("scroll", this.scrollListener);
  },
  methods: {
    scrollToTheTop() {
      window.scrollTo(0, 0);
    },
    scrollListener() {
      const elemento = document.querySelector(this.elementoReferenciaId);
      this.exibirBotao = !this.isInViewport(elemento);
    },
    isInViewport(el) {
      if (!el) {
        return false;
      }

      const rect = el.getBoundingClientRect();
      return (
        rect.top + rect.height >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.bt-scroll {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background-color: #f7a311;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  // box-shadow: 0 0 .5rem rgba(0, 0, 0, 0.397);
  svg {
    height: 1.2rem;
    width: 1.2rem;
    fill: #256f0d;
  }
}
</style>
