import Vue from "vue";
import VueI18n from "vue-i18n";

import Cookies from "js-cookie";

import {
  LOCALE_ATUAL_INVESTE_GOIAS,
  LOCALES_DISPONIVEIS,
} from "./config/constantes";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

let preferredBrowserLanguage = navigator.language
  ? navigator.language.split("-")[0]
  : null;

if (!Object.keys(LOCALES_DISPONIVEIS).includes(preferredBrowserLanguage)) {
  preferredBrowserLanguage = null;
}

let localeArmazenado = Cookies.get(LOCALE_ATUAL_INVESTE_GOIAS);
if (!Object.keys(LOCALES_DISPONIVEIS).includes(localeArmazenado)) {
  localeArmazenado = null;
}

const locale =
  localeArmazenado ||
  preferredBrowserLanguage ||
  process.env.VUE_APP_I18N_LOCALE ||
  "pt";

Cookies.set(LOCALE_ATUAL_INVESTE_GOIAS, locale);

export default new VueI18n({
  locale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages(),
});
