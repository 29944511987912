<template>
  <div class="index" id="topo_inicio">
    <div class="parte1">
      <div class="centro">
        <div class="banner">
          <div class="bk-preto">
            <h1>{{ $t("inicio.invista-goias-titulo") }}</h1>

            <i18n
              path="inicio.invista-goias-mensagem"
              tag="p"
              for="inicio.invista-goias-link"
            >
              <router-link :to="{ name: CONTATO }">{{
                $t("inicio.invista-goias-link")
              }}</router-link>
            </i18n>

            <router-link class="botao-banner" :to="{ name: CONTATO }">{{
              $t("inicio.invista-preencha-formulario")
            }}</router-link>
            <a href="#sobre"><div class="arrow"></div></a>
          </div>
        </div>
      </div>
    </div>
    <AppBotaoScroll elemento-referencia-id="#the_menu" />
    <SobreGoias />
    <Industrias />
    <LinhasCredito />
    <ProgramasIncentivo />
  </div>
</template>

<script>
import Industrias from "../components/inicio/Industrias";
import SobreGoias from "../components/inicio/SobreGoias";
import LinhasCredito from "../components/inicio/LinhasCredito";
import ProgramasIncentivo from "../components/inicio/ProgramasIncentivo";
import AppBotaoScroll from "../components/AppBotaoScroll";

import { CONTATO } from "../router/routeNames";

import metadados from "../helpers/metadados";

export default {
  name: "Inicio",
  metaInfo() {
    return {
      meta: [...metadados(this.$route)],
    };
  },
  components: {
    Industrias,
    LinhasCredito,
    SobreGoias,
    ProgramasIncentivo,
    AppBotaoScroll,
  },
  data() {
    return {
      CONTATO,
    };
  },
};
</script>

<style lang="scss" scoped>
.index {
  margin-top: 6rem;
  .parte1 {
    .centro {
      padding: 0;
    }
    .banner {
      background-image: url("../assets/images/banner-mobile.jpg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-attachment: fixed;
      height: calc(100vh - 5.5rem);
      -webkit-height: calc(100vh - 5.5rem);
      margin-top: -0.5rem;
      position: relative;
      .bk-preto {
        background-color: rgba(0, 0, 0, 0.623);
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h1 {
          color: white;
          text-align: center;
          font-family: "Poppins-SemiBold";
          text-shadow: 0 0 0.5rem black;
          margin: 0;
        }
        p {
          color: white;
          text-align: center;
          text-shadow: 0 0 0.5rem black;
          margin: 0 auto;
          max-width: 45rem;
          padding: 0 1rem;
          a {
            color: white;
            font-style: italic;
            margin-top: 0;
          }
        }
        .botao-banner {
          text-align: center;
          border: white solid 0.15rem;

          border-radius: 0.2rem;
          width: 15rem;
          margin: 0 auto;
          text-decoration: none;
          color: white;
          margin-top: 2rem;
          transition: ease-in-out 0.2s;
          font-family: "Poppins-Medium";
          padding: 0.2rem;
        }
        .botao-banner:hover {
          background-color: white;
          color: black;
        }
        .arrow,
        .arrow:before {
          position: absolute;
          left: 50%;
        }
        .arrow {
          width: 40px;
          height: 40px;
          bottom: 5%;
          margin: -20px 0 0 -20px;
          -webkit-transform: rotate(45deg);
          border-left: none;
          border-top: none;
          border-right: 2px #fff solid;
          border-bottom: 2px #fff solid;
        }

        .arrow:before {
          content: "";
          width: 20px;
          height: 20px;
          top: 50%;
          margin: -10px 0 0 -10px;
          border-left: none;
          border-top: none;
          border-right: 1px #fff solid;
          border-bottom: 1px #fff solid;
          animation-duration: 2s;
          animation-iteration-count: infinite;
          animation-name: arrow;
        }
        @keyframes arrow {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
            transform: translate(-10px, -10px);
          }
        }
        svg {
          height: 4rem;
          width: 4rem;
          position: absolute;
          bottom: 1rem;
          right: 1rem;
        }
      }
    }
  }
}

.alto-contraste {
  .parte1 {
    .centro {
      .banner {
        .bk-preto {
          background-color: rgba(0, 0, 0, 0.726);
          box-shadow: 0 0 0.5rem rgba(255, 255, 255, 0.137);
        }
      }
    }
  }
}
@supports (-webkit-touch-callout: none) {
  .index {
    .parte1 {
      .centro {
        .banner {
          background-attachment: scroll !important;
        }
      }
    }
  }
}

@media screen and (min-width: "50em") {
  .index {
    .parte1 {
      margin-top: 6.5rem;
      .centro {
        padding: 0.5rem;
      }
      .banner {
        background-image: url("../assets/images/banner-index.png");
        height: 20rem;
        background-attachment: unset;
        .arrow {
          display: none;
        }
      }
    }
  }
}
@media screen and (min-width: "64em") {
  .index {
    margin-top: 1rem;
    .parte1 {
      margin: 0;
    }
  }
}
</style>
