import { render, staticRenderFns } from "./FormAreasDisponiveis.vue?vue&type=template&id=cc69d512&scoped=true&"
import script from "./FormAreasDisponiveis.vue?vue&type=script&lang=js&"
export * from "./FormAreasDisponiveis.vue?vue&type=script&lang=js&"
import style0 from "./FormAreasDisponiveis.vue?vue&type=style&index=0&id=cc69d512&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc69d512",
  null
  
)

export default component.exports