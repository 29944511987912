<template>
  <div class="info-mapa centro">
    <div>
      <div class="bt-tabela">
        <button
          v-for="tab in tabs"
          :key="tab.value"
          type="button"
          @click="selectedTab = tab"
          :class="{ 'table-active': selectedTab.value == tab.value }"
        >
          {{ tab.label }}
        </button>
      </div>
    </div>
    <div class="conteudo-tabelas">
      <div class="tabela-industriais" v-if="selectedTab.value == tabs[0].value">
        <table>
          <thead>
            <tr>
              <th>{{ $t("dados-mapa.polo-industrial") }}</th>
              <th>{{ $t("dados-mapa.categoria") }}</th>
              <th>{{ $t("dados-mapa.principais_segmentos") }}</th>
              <th>{{ $t("dados-mapa.raio_influencia") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="polo in polosIndustriais" :key="polo.id">
              <td class="topo-card">{{ polo.nome }}</td>
              <td data-label="Categoria:">{{ polo.categoria }}</td>
              <td data-label="Principais Segmentos:">
                {{ polo.principais_segmentos }}
              </td>
              <td data-label="Raio de Influência:">
                {{ polo.raio_influencia }}
              </td>
            </tr>
            <tr v-if="polosIndustriais.length == 0">
              <td colspan="4" class="td-centro">
                {{ $t("dados-mapa.sem-dados") }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="tabela-aeroportos" v-if="selectedTab.value == tabs[1].value">
        <table>
          <thead>
            <tr>
              <th>{{ $t("dados-mapa.aeroporto") }}</th>
              <th>{{ $t("dados-mapa.municipio") }}</th>
              <th class="th-centro">{{ $t("dados-mapa.altitude") }}</th>

              <th class="th-centro">
                {{ $t("dados-mapa.comprimento-pista") }}
              </th>
              <th class="th-centro">{{ $t("dados-mapa.largura-pista") }}</th>
              <th class="th-centro">{{ $t("dados-mapa.operacao") }}</th>
              <th>{{ $t("dados-mapa.privado") }}?</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="aeroporto in aeroportos" :key="aeroporto.id">
              <td class="topo-card">{{ aeroporto.nome }}</td>
              <td data-label="Município:">{{ aeroporto.municipio.nome }}</td>
              <td class="td-centro" data-label="Altitude:">
                {{ aeroporto.altitude }}m
              </td>
              <td class="td-centro" data-label="Comprimento da pista:">
                {{ aeroporto.comprimento_pista }}m
              </td>
              <td class="td-centro" data-label="Largura da pista:">
                {{ aeroporto.largura_pista }}m
              </td>
              <td class="td-centro" data-label="Operação:">
                {{ aeroporto.operacao }}
              </td>
              <td data-label="Privado?:">
                {{ aeroporto.privado ? "Sim" : "Não" }}
              </td>
            </tr>
            <tr v-if="aeroportos.length == 0">
              <td colspan="7" class="td-centro">
                {{ $t("dados-mapa.sem-dados") }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="tabela-aerodromos" v-if="selectedTab.value == tabs[2].value">
        <table>
          <thead>
            <tr>
              <th>{{ $t("dados-mapa.aerodromo") }}</th>
              <th>{{ $t("dados-mapa.municipio") }}</th>
              <th class="th-centro">{{ $t("dados-mapa.altitude") }}</th>

              <th class="th-centro">
                {{ $t("dados-mapa.comprimento-pista") }}
              </th>
              <th class="th-centro">{{ $t("dados-mapa.largura-pista") }}</th>
              <th class="th-centro">{{ $t("dados-mapa.operacao") }}</th>
              <th>{{ $t("dados-mapa.privado") }}?</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="aerodromo in aerodromos" :key="aerodromo.id">
              <td class="topo-card">{{ aerodromo.nome }}</td>
              <td data-label="Município:">{{ aerodromo.municipio.nome }}</td>
              <td class="td-centro" data-label="Altitude:">
                {{ aerodromo.altitude }}m
              </td>
              <td class="td-centro" data-label="Comprimento da pista:">
                {{ aerodromo.comprimento_pista }}m
              </td>
              <td class="td-centro" data-label="Largura da pista:">
                {{ aerodromo.largura_pista }}m
              </td>
              <td class="td-centro" data-label="Operação:">
                {{ aerodromo.operacao }}
              </td>
              <td data-label="Privado?:">
                {{ aerodromo.privado ? "Sim" : "Não" }}
              </td>
            </tr>
            <tr v-if="aerodromos.length == 0">
              <td colspan="7" class="td-centro">
                {{ $t("dados-mapa.sem-dados") }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="tabela-helipontos" v-if="selectedTab.value == tabs[3].value">
        <table>
          <thead>
            <tr>
              <th>{{ $t("dados-mapa.heliponto") }}</th>
              <th>{{ $t("dados-mapa.municipio") }}</th>
              <th class="th-centro">{{ $t("dados-mapa.altitude") }}</th>
              <th>{{ $t("dados-mapa.dimensoes-heliponto") }}</th>
              <th>{{ $t("dados-mapa.operacao") }}</th>
              <th class="th-centro">{{ $t("dados-mapa.privado") }}?</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="heliponto in helipontos" :key="heliponto.id">
              <td class="topo-card">{{ heliponto.nome }}</td>
              <td data-label="Município:">{{ heliponto.municipio.nome }}</td>
              <td class="td-centro" data-label="Altitude:">
                {{ heliponto.altitude }}m
              </td>
              <td data-label="dimensões">
                {{ heliponto.dimensoes_heliponto }}
              </td>
              <td data-label="operação:">{{ heliponto.operacao }}</td>
              <td class="td-centro" data-label="Categoria: Privado">
                {{ heliponto.privado ? "Sim" : "Não" }}
              </td>
            </tr>
            <tr v-if="helipontos.length == 0">
              <td colspan="6" class="td-centro">
                {{ $t("dados-mapa.sem-dados") }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MapasTabela",
  props: {
    aeroportos: {
      type: Array,
      default: () => [],
    },
    helipontos: {
      type: Array,
      default: () => [],
    },
    aerodromos: {
      type: Array,
      default: () => [],
    },
    polosIndustriais: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const tabs = [
      {
        label: this.$t("dados-mapa.polos-industriais"),
        value: "polos_industriais",
      },
      { label: this.$t("dados-mapa.aeroportos"), value: "aeroportos" },
      { label: this.$t("dados-mapa.aerodromos"), value: "aerodromos" },
      { label: this.$t("dados-mapa.helipontos"), value: "helipontos" },
    ];
    return {
      tabs,
      selectedTab: tabs[0],
    };
  },
};
</script>

<style lang="scss" scoped>
.bt-tabela {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.2rem;
  button {
    font-size: 1rem;
    background-color: #ffa200;
    border: none;
    color: #134104;
    font-family: 'Poppins-Medium';
    cursor: pointer;
    padding: 0.3rem;
  }
  .table-active {
    background-color: #134104;
    color: white;
    box-shadow: none;
  }
}
.conteudo-tabelas {
  padding: 1rem;
  background-color: #e2e7e1;
  margin-bottom: 2rem;
  table {
    border-collapse: collapse;
    width: 100%;
    thead {
      background-color: #134104;
      color: white;
      th {
        padding: 0.5rem;
        text-align: left;
        font-size: .9rem;
      }
      .th-centro {
        text-align: center;
      }
    }
    tbody{
      tr{
        td{
          padding: .2rem .5rem;
          font-size: .9rem;
        }
        .td-centro {
          text-align: center;
        }
      }
      tr:nth-child(even) {
        background-color: white;
      }
      .sem-dados {
        text-align: center;
        font-style: italic;
        font-size: .9rem;
      }
    }
  }
}
@media screen and(min-width: '45em') {
  .bt-tabela {
    display: block;
    button {
      padding: 0.5rem 2rem;
      border-radius: 0.5rem 0.5rem 0 0;
      box-shadow: inset 0 0rem 0.4rem rgba(0, 0, 0, 0.199);
    }
    .table-active {
      background-color: #e2e7e1;
      color: #134104;
      box-shadow: none;
    }
  }
}
@media screen and(max-width: '45em') {
  .conteudo-tabelas {
    table {
      width: 100%;
      tbody {
        background-color: transparent;
      }
      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      tr {
        display: block;
        margin-bottom: 1em;
        background-color: white;
        padding-bottom: 1rem;
        width: 100% !important;
        td {
          padding: 0rem !important;
        }
        td:nth-child(2) {
          text-align: left !important;
        }
        td:nth-child(3) {
          text-align: left !important;
        }
        td:nth-child(4) {
          text-align: left !important;
        }
        td:nth-child(5) {
          text-align: left !important;
          max-width: 50rem;
        }
        .acao {
          display: none;
        }
      }
      .sem-dados {
        background-color: transparent;
      }
      td {
        display: block;
        min-height: 1.3rem;
        margin: 0rem 0.5rem;
        font-size: 0.9rem;
        img {
          height: 1.5rem;
          margin: 0rem 1rem;
        }
      }

      td::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        color: #0a4a22;
        font-family: "Poppins";
        margin-right: 0.5rem;
      }
      .topo-card {
        background-color: #256f0d;
        font-family: "Poppins";
        text-align: center;
        margin: 0rem 0rem 0.3rem 0rem;
        font-weight: 600 !important;
        font-size: 0.9rem;
        color: white;
      }
    }
  }
}
</style>
