<template>
  <div class="tela-sucesso">
    <div class="conteudo">
      <img
        class="envelope"
        src="../../assets/images/send-sucess.png"
        alt="Envelope com um check, indicando que o formulário foi enviado com sucesso"
      />
      <h1>Formulário enviado com sucesso!</h1>
      <p>Obrigado!</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormularioSucesso",
};
</script>

<style lang="scss">
.tela-sucesso {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  h1 {
    font-family: "Poppins-Bold";
    color: #1c5808;
    line-height: 2.5rem;
    margin-bottom: 0;
  }
  p{
    font-size: 2rem;
    margin: 0;
    color: grey;
  }
  .envelope {
    width: 10rem;
  }
}
</style>
