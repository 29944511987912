<template>
  <div class="politica-privacidade">
    <div class="conteudo centro" v-if="exibirPolitica">
      <i18n
        path="privacidade.mensagem"
        tag="div"
        for="privacidade.botao-concordo"
      >
        <a
          href="https://www.goias.gov.br/servico/politica-de-privacidade.html"
          >{{ $t("privacidade.politica") }}</a
        >
      </i18n>
      <button @click="aceitarPolitica">
        {{ $t("privacidade.botao-concordo") }}
      </button>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";

import { POLITICA_PRIVACIDADE_ACEITE } from "../config/constantes";

export default {
  name: "ThePoliticaPrivacidade",
  data() {
    return {
      exibirPolitica: !Cookies.get(POLITICA_PRIVACIDADE_ACEITE),
    };
  },
  methods: {
    aceitarPolitica() {
      Cookies.set(POLITICA_PRIVACIDADE_ACEITE, "true");
      this.exibirPolitica = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.politica-privacidade {
  background-color: #2e2e2e;
  box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.459);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: 0.2s;
  .conteudo {
    color: white;
    font-size: 0.8rem;
    display: grid;
    align-items: center;
    padding: 1rem .5rem;
    a{
      color: white;
    }
    button {
      height: 2rem;
      font-family: "Poppins-Medium";
      margin-top: 1rem;
      display: block;
      color: #2e2e2e;
      background-color: #f7a311;
      border: none;
      border-radius: 0.2rem;
      padding: 0.2rem 0.5rem;
      cursor: pointer;
      transition: 0.2s;
      font-size: 0.9rem;
      max-width: 8rem;
    }
    button:hover {
      background-color: #f7a311;
    }
  }
}
@media screen and(min-width: '40em') {
  .politica-privacidade{
    .conteudo{
      display: flex;
      button{
        margin: 0rem .5rem 0;
      }
    }
  }
}
</style>
