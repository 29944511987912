<template>
  <div>
    <div class="parte6">
      <div class="centro">
        <h1>{{ $t("footer.links-uteis") }}</h1>
        <div class="logos">
          <a
            href="https://www.goiasturismo.go.gov.br/"
            target="blank"
            class="logo-diurno"
          >
            <img
              src="../assets/images/logo-turismo.png"
              alt="Logo do Goiás Turismo"
              title="Acessar Site Goiás Turismo"
            />
          </a>
          <a
            href="https://www.goiasturismo.go.gov.br/"
            target="blank"
            class="logo-noturno"
          >
            <img
              src="../assets/images/logo-goiasturismo-branco.png"
              alt="Logo do Goiás Turismo"
              title="Acessar Site Goiás Turismo"
            />
          </a>
          <a
            href="https://www.juceg.go.gov.br/"
            target="blank"
            class="logo-diurno"
          >
            <img
              src="../assets/images/logo-juceg.png"
              alt="Logo do Juceg Goiás"
              title="Acessar Site da Juceg"
            />
          </a>
          <a
            href="https://www.juceg.go.gov.br/"
            target="blank"
            class="logo-noturno"
          >
            <img
              src="../assets/images/logo-juceg-branco.png"
              alt="Logo do Juceg Goiás"
              title="Acessar Site da Juceg"
            />
          </a>
          <a
            href="https://www.goiasfomento.com/"
            target="blank"
            class="logo-diurno"
          >
            <img
              src="../assets/images/logo-fomento.png"
              alt="Logo do Goias Fomento"
              title="Acessar o site GoiásFomento"
            />
          </a>
          <a
            href="https://www.goiasfomento.com/"
            target="blank"
            class="logo-noturno"
          >
            <img
              src="../assets/images/logo-fomento-bk.png"
              alt="Logo do Goias Fomento"
              title="Acessar o site GoiásFomento"
            />
          </a>
          <a
            href="https://www.goiasparcerias.go.gov.br/"
            target="blank"
            class="logo-diurno"
          >
            <img
              src="../assets/images/logo-parcerias.png"
              alt="Logo do parcerias"
              title="Acessar o site do Goias Parcerias"
            />
          </a>
          <a
            href="https://www.goiasparcerias.go.gov.br/"
            target="blank"
            class="logo-noturno"
          >
            <img
              src="../assets/images/logo-parcerias-branco.png"
              alt="Logo do parcerias"
              title="Acessar o site do Goias Parcerias"
            />
          </a>
          <a
            href="https://www.imb.go.gov.br/"
            target="blank"
            class="logo-diurno"
          >
            <img
              src="../assets/images/logo-imb-verde.png"
              alt="Logo do IMB"
              title="Acessar site do IMB"
            />
          </a>
          <a
            href="https://www.imb.go.gov.br/"
            target="blank"
            class="logo-noturno"
          >
            <img
              src="../assets/images/logo-imb-branco.png"
              alt="Logo do IMB"
              title="Acessar site do IMB"
            />
          </a>
          <a
            href="http://www.codego.com.br/acessoasinformacoes/"
            target="blank"
            class="logo-diurno"
          >
            <img
              src="../assets/images/logo-code-go.png"
              alt="Logo do CodeGO"
              title="Acessar Site do CodeGO"
            />
          </a>
          <a
            href="http://www.codego.com.br/acessoasinformacoes/"
            target="blank"
            class="logo-noturno"
          >
            <img
              src="../assets/images/logo-codego-branco.png"
              alt="Logo do CodeGO"
              title="Acessar Site do CodeGO"
            />
          </a>
        </div>
        <h3>{{ $t("footer.secretarias") }}:</h3>
        <div class="secretarias">
          <a href="https://www.sic.go.gov.br/" target="blank"
            >Secretaria de Indústria, Comércio e Serviço</a
          >
          <a href="https://www.agricultura.go.gov.br/" target="blank"
            >Secretaria de Agricultura, Pecuária e Abastecimento</a
          >
          <a href="https://www.cultura.go.gov.br/" target="blank"
            >Secretaria de Cultura</a
          >
          <a href="https://www.desenvolvimento.go.gov.br/" target="blank"
            >Secretaria de Desenvolvimento e Inovação</a
          >
        </div>
      </div>
    </div>
    <footer>
      <div class="centro">
        <div class="left">
          <img
            src="../assets/images/logo-investe-verde.png"
            class="logo-investe-footer"
            alt="Logo do Investe Goiás"
          />
          <p>
            Rua 82, nº400 - Ed. Palácio Pedro Ludovico,<br />
            5º Andar, Setor Central <br />
            74015-908 - Goiânia-GO
          </p>
          <div class="icons-social">
            <a href="https://www.facebook.com/sicgoias/" target="_blank" title="Facebbok da SIC"
              ><IconFacebook
            /></a>
            <a href="https://www.instagram.com/sicgoias/?hl=pt" target="_blank" title="Instagram da SIC"
              ><IconInstagram
            /></a>
            <a href="https://twitter.com/sic_go" target="_blank" title="Twitter da SIC"
              ><IconTwitter
            /></a>
          </div>
        </div>
        <div class="center">
          <h4>{{ $t("footer.contato") }}</h4>
          <p>
            {{ $t("footer.horario-atendimento") }} <br />
            +55 62 3201-5580 <br />
            +55 62 3201-5500 <br />
            <a href="mailto:investe@goias.gov.br">investe@goias.gov.br</a><br />
            <a href="mailto:internacional@goias.gov.br"
              >internacional@goias.gov.br</a
            >
          </p>
        </div>
        <div class="right">
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import IconFacebook from "../assets/images/facebook.svg";
import IconInstagram from "../assets/images/instagram.svg";
import IconTwitter from "../assets/images/twitter.svg";

export default {
  name: "TheFooter",
  components: {
    IconFacebook,
    IconInstagram,
    IconTwitter,
  },
};
</script>

<style lang="scss" scoped>
.parte6 {
  background-color: #e2e7e1;
  padding-bottom: 2rem;
  h1 {
    font-family: "Poppins-SemiBold";
    color: #256f0d;
  }
  .logos {
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    img {
      max-width: 100%;
      max-height: 4rem;
    }
    .logo-noturno {
      display: none;
    }
  }
  h3 {
    font-family: "Poppins-SemiBold";
    color: #256f0d;
    font-size: 1.5rem;
  }
  .secretarias {
    display: grid;
    a {
      text-decoration: none;
      color: black;
      transition: 0.3s;
    }
    a:hover {
      text-decoration: underline;
      padding-left: 1rem;
    }
  }
}
footer {
  margin-top: 2rem;
  padding-bottom: 2rem;
  .centro {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .left {
    img {
      height: 2.5rem;
    }
    p {
      display: none;
    }
    .icons-social {
      display: flex;
      justify-content: space-between;
      width: 7rem;
      margin-top: 1rem;
      svg {
        height: 1.1rem;
        width: 1.1rem;
        transition: 0.15s;
      }
    }
  }
  .center {
    h4 {
      display: none;
    }
    p {
      display: none;
    }
    a{
      text-decoration: none;
      color: #256f0d;
    }
    a:hover{
      text-decoration: underline;
    }
  }
  .right {
    .logo-goias-bco {
      display: none;
    }
    svg {
      height: 4rem;
      width: 4rem;
    }
  }
}
.alto-contraste {
  .parte6 {
    background-color: #242424;
    h1 {
      color: #f7a311;
    }
    h3 {
      color: #f7a311;
    }
    a {
      color: white;
    }
    .logos {
      .logo-diurno {
        display: none;
      }
      .logo-noturno {
        display: block;
      }
    }
  }
  footer {
    p {
      color: white;
    }
    .left {
      p {
        color: white;
      }
    }
    .centro {
      p {
        color: white;
      }
      a{
        color: white;
      }
    }
    .right{
      .logo-goias-bco {
        display: block;
      }
      .logo-goias-verde{
        display: none;
      }
    }
  }
}
@media screen and (min-width: "50em") {
  .parte6 {
    .logos {
      grid-template-columns: repeat(7, 1fr);
    }
    .secretarias {
      grid-template-columns: 1fr 1fr;
    }
  }
}
@media screen and (min-width: "64em") {
  footer {
    .left {
      p {
        display: block;
        margin: 0;
        color: #256f0d;
      }
    }
    .center {
      h4 {
        display: block;
        color: #256f0d;
        font-family: "Poppins-Bold";
        font-size: 1.1rem;
        margin: 0;
      }
      p {
        display: block;
        margin: 0;
        color: #256f0d;
      }
    }
    .right {
      img {
        height: 4rem;
        width: 4rem;
      }
    }
  }
}
</style>
