<template>
  <section class="mockup">
    <header>
      <p>{{ $t("dados-mapa.polo-industrial") }} {{ poloIndustrial.nome }}</p>
      <a title="Close" @click="close">
        x
      </a>
    </header>
    <div class="informacoes">
      <div>
        <p>
          <strong>{{ $t("dados-mapa.categoria") }}:</strong>
          {{ poloIndustrial.categoria }}
        </p>
        <p>
          <strong>{{ $t("dados-mapa.principais_segmentos") }}:</strong>
          {{ poloIndustrial.principais_segmentos }}
        </p>
        <p>
          <strong>{{ $t("dados-mapa.raio_influencia") }}: </strong>
          {{ poloIndustrial.raio_influencia }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "MapasOverlayCardPoloIndustrial",
  props: {
    poloIndustrial: {
      type: Object,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.mockup{
  background-color: white;
  box-shadow: 0 0 .5rem rgba(0, 0, 0, 0.356);
  width: 20rem;
  header{
    display: flex;
    justify-content: space-between;
    padding: .5rem;
    background-color: #134104;
    color: white;
    p{
      margin: 0;
      font-weight: 800;
    }
    a{
      margin-left: 1rem;
      color: red;
      font-family: 'Poppins-Bolder';
      cursor: pointer;
    }
  }
  .informacoes{
    padding: .5rem 0;
    p{
      margin: .2rem .5rem;
    }
  }
}
</style>
