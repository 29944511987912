import VueRouter from "vue-router";

import * as routeNames from "./routeNames";

import NaoEncontrado from "../views/NaoEncontrado";
import Inicio from "../views/Inicio";
import DadosEmMapas from "../views/DadosEmMapas";
import MapaSite from "../views/MapaSite";
import Contato from "../views/Contato";
import ExibirVideo from "../views/ExibirVideo"
import FormularioInformacoesMunicipais from "../views/informacoes_municipais/FormularioInformacoesMunicipais";

const router = new VueRouter({
    routes: [{
            name: routeNames.INICIO,
            path: "/",
            component: Inicio,
        },
        {
            name: routeNames.DADOS_EM_MAPAS,
            path: "/dados-em-mapas",
            component: DadosEmMapas,
        },
        {
            name: routeNames.MAPA_SITE,
            path: "/mapa-site",
            component: MapaSite,
        },
        {
            name: routeNames.CONTATO,
            path: "/contato",
            component: Contato,
        },
        {
            name: routeNames.EXIBIR_VIDEO,
            path: "/video",
            component: ExibirVideo
        },
        {
            name: routeNames.NAO_ENCONTRADO,
            path: "/nao-encontrado",
            component: NaoEncontrado,
        },
        {
            name: routeNames.FORMULARIO_INFORMACOES_MUNICIPAIS,
            path: "/formulario-informacoes-municipais",
            component: FormularioInformacoesMunicipais,
        },
        {
            path: "*",
            redirect: (to) => {
                return { name: routeNames.NAO_ENCONTRADO, query: { url: to.path } };
            },
        },
    ],
    mode: "history",
    scrollBehavior(to) {
        if (to.hash) {
            return { selector: to.hash };
        }
        return { x: 0, y: 0 };
    },
});

// esconde os erros de NavigationDuplicated
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => {
        if (
            err.name !== "NavigationDuplicated" &&
            !err.message.includes("Avoided redundant navigation to current location")
        ) {
            console.error(err);
        }
    });
};

export default router;