<template>
  <div>
    <div class="centro">
      <h1>{{ $t("contato.titulo") }}</h1>

      <div
        class="tela-modal"
        v-if="exibirMensagemSucesso"
        @click.self="exibirMensagemSucesso = false"
      >
        <div class="caixa-modal">
          <a @click="exibirMensagemSucesso = false">x</a>
          <IconSucess />
          <p>{{ $t("contato.sucesso") }}</p>
        </div>
      </div>

      <div v-if="erros">
        <p class="msg-erro" v-for="erro in erros" :key="erro">
          {{ erro }}
        </p>
      </div>
      <div class="row2">
        <form class="left" @submit="enviar">
          <div class="row2">
            <div class="item">
              <label>{{ $t("contato.razao-social") }}:</label>
              <input type="model" v-model="razaoSocial" required />
            </div>
            <div class="item">
              <label>{{ $t("contato.nome") }}:</label>
              <input type="model" v-model="nomeContato" required />
            </div>
          </div>
          <div class="row2">
            <div class="item">
              <label>{{ $t("contato.numero") }}:</label>
              <input type="model" v-model="numeroContato" required />
            </div>
            <div class="item">
              <label>{{ $t("contato.email") }}:</label>
              <input type="model" v-model="emailContato" required />
            </div>
          </div>

          <div class="item">
            <label>{{ $t("contato.mensagem") }}:</label>
            <textarea v-model="outrasInformacoes" required></textarea>
          </div>
          <p class="msg-erro-cap" v-if="erroCaptcha">{{ erroCaptcha }}</p>
          <vue-hcaptcha
            sitekey="3625df88-4168-4300-9de9-82cff6537a6b"
            :language="this.$i18n.locale"
            @verify="captchaVerificado"
            ref="captcha"
          ></vue-hcaptcha>
          <AppLoading texto="" v-if="enviando" />
          <div class="bt-enviar">
            <button type="submit" v-if="!enviando">Enviar</button>
          </div>
        </form>
        <div class="right">
          <p>
            <strong>{{ $t("footer.horario-atendimento-titulo") }}</strong>
            {{ $t("footer.horario-atendimento") }} <br />
            +55 62 3201-5580 <br />
            +55 62 3201-5500 <br />
            <a href="mailto:investe@goias.gov.br">investe@goias.gov.br</a><br />
            <a href="mailto:internacional@goias.gov.br"
              >internacional@goias.gov.br</a
            >
          </p>
          <h3>{{ $t("footer.endereco") }}:</h3>
          <p>
            Rua 82, nº400 - Ed. Palácio Pedro Ludovico,<br />
            5º Andar, Setor Central <br />
            74015-908 - Goiânia-GO
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";

import ContatosSiteApi from "../services/ContatosSiteApi";

import AppLoading from "../components/AppLoading";

import IconSucess from "../assets/images/sucesso.svg";

import metadados from "../helpers/metadados";

export default {
  name: "Contato",
  metaInfo() {
    return {
      meta: [...metadados(this.$route)],
    };
  },
  data() {
    return {
      enviando: false,
      erros: null,
      razaoSocial: null,
      nomeContato: null,
      numeroContato: null,
      emailContato: null,
      outrasInformacoes: null,
      exibirMensagemSucesso: false,
      verificado: false,
      captchaToken: null,
      captchaEkey: null,
      erroCaptcha: null,
    };
  },
  components: {
    AppLoading,
    VueHcaptcha,
    IconSucess,
  },
  created() {
    this.contatosSiteApi = new ContatosSiteApi();
  },
  methods: {
    async enviar(event) {
      event.preventDefault();

      try {
        if (!this.verificado) {
          this.erroCaptcha = "Responda o captcha";
          return;
        }

        this.erroCaptcha = null;
        this.enviando = true;
        this.erros = null;
        this.exibirMensagemSucesso = false;

        await this.contatosSiteApi.postContatoSite({
          razaoSocial: this.razaoSocial,
          nomeContato: this.nomeContato,
          numeroContato: this.numeroContato,
          emailContato: this.emailContato,
          outrasInformacoes: this.outrasInformacoes,
          token: this.captchaToken,
          eKey: this.captchaEkey,
        });
        this.enviando = false;
        this.exibirMensagemSucesso = true;
        this.razaoSocial = null;
        this.nomeContato = null;
        this.numeroContato = null;
        this.emailContato = null;
        this.outrasInformacoes = null;

        this.resetCaptcha();
      } catch (err) {
        console.log(err, err.response);
        this.enviando = false;
        if (err && err.response && err.response.data) {
          if (Array.isArray(err.response.data)) {
            this.erros = err.response.data;
          } else {
            if (
              typeof err.response.data !== "string" &&
              err.response.data.mensagem
            ) {
              this.erros = [err.response.data.mensagem];
            } else {
              this.erros = [err.response.data];
            }
          }
        } else {
          this.erros = [this.$t("contato.erro")];
        }
        this.resetCaptcha();
      }
    },
    captchaVerificado(token, eKey) {
      this.captchaToken = token;
      this.captchaEkey = eKey;
      this.verificado = true;
      this.erroCaptcha = null;
    },
    resetCaptcha() {
      this.$refs.captcha.reset();

      this.captchaEkey = null;
      this.captchaToken = null;
      this.verificado = false;
      this.erroCaptcha = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.centro {
  margin-top: 5rem;
  h1 {
    font-family: "Poppins-SemiBold";
    color: #256f0d;
    margin-bottom: 0.5rem;
  }
  h3 {
    font-family: "Poppins-SemiBold";
    color: #f7a311;
    margin-bottom: 0;
    font-size: 1.5rem;
  }
  .item {
    margin: 0.5rem 0;
    input {
      width: calc(100% - 1rem);
      padding-left: 0.5rem;
      height: 1.5rem;
    }
    textarea {
      width: calc(100% - 1rem);
      padding-left: 0.5rem;
      height: 10rem;
    }
  }
  #hcap-script {
    text-align: center;
  }
  .msg-erro {
    color: red;
  }
  .msg-erro-cap {
    text-align: center;
    margin-bottom: 0;
    color: red;
  }
  .bt-enviar {
    text-align: center;
    margin: 1rem 0;
    button {
      font-size: 1rem;
      padding: 0.2rem 2.5rem;
      background-color: #256f0d;
      border: none;
      font-family: "Poppins-SemiBold";
      color: white;
      transition: 0.2s;
      border-radius: 0.2rem;
      cursor: pointer;
    }
    button:hover {
      background-color: #1b5009;
    }
  }
  .tela-modal {
    background-color: rgba(0, 0, 0, 0.329);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    .caixa-modal {
      background-color: white;
      max-width: 20rem;
      // height: 15rem;
      padding: 0 1rem;
      position: relative;
      margin: 0 1rem;
      a {
        cursor: pointer;
        position: absolute;
        right: 1rem;
        color: rgb(71, 71, 71);
      }
      svg {
        height: 5rem;
        width: 5rem;
        margin: 0 auto;
        fill: #256f0d;
        text-align: center;
        display: block;
        margin-top: 2.5rem;
      }
      p {
        font-family: "Poppins-Medium";
        text-align: center;
        color: #256f0d;
        font-size: 1.2rem;
      }
    }
  }
  .right {
    p {
      color: #1b5009;
    }
    a {
      color: #1b5009;
    }
  }
}
.alto-contraste {
  h1 {
    color: #f7a311;
  }
  .item {
    label {
      color: white;
    }
  }
  .right {
    p {
      color: white;
    }
    a {
      color: white;
    }
  }
}
@media screen and (min-width: "64em") {
  .centro {
    margin-top: 0;
    margin-bottom: 2rem;
    .row2 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }
    .item {
      textarea {
        height: 8rem;
      }
    }
    .bt-enviar {
      button {
        padding: 0.5rem 5rem;
      }
    }
    .right {
      padding-left: 2rem;
    }
  }
}
</style>
