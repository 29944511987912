<template>
  <div id="sobre" class="parte2">
    <div class="centro">
      <h1>{{ $t("sobre-goias.titulo") }}</h1>
      <div class="texto">
        <p>{{ $t("sobre-goias.primeiro-paragrafo") }}<br /></p>
        <p>
          {{ $t("sobre-goias.segundo-paragrafo") }}
        </p>
      </div>
      <div class="row2">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11313105.487173628!2d-52.931086011458454!3d-11.762106284078875!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x935db96386b920f5%3A0x8c6d8f8cd8cf0d3!2zR29pw6Fz!5e0!3m2!1spt-BR!2sbr!4v1620836383873!5m2!1spt-BR!2sbr"
          width="600"
          height="450"
          style="border:0;"
          allowfullscreen=""
          loading="lazy"
        ></iframe>

        <p class="lista">
          <span>&#10003;</span>{{ $t("sobre-goias.lista[0]") }} <br />
          <span>&#10003;</span>{{ $t("sobre-goias.lista[1]") }} <br />
          <span>&#10003;</span>{{ $t("sobre-goias.lista[2]") }}<br />
          <span>&#10003;</span>{{ $t("sobre-goias.lista[3]") }} <br />
          <span>&#10003;</span>{{ $t("sobre-goias.lista[4]") }} <br />
          <span>&#10003;</span>{{ $t("sobre-goias.lista[5]") }} <br />
          <span>&#10003;</span>{{ $t("sobre-goias.lista[6]") }} <br />
          <span>&#10003;</span>{{ $t("sobre-goias.lista[7]") }} <br />
          <span>&#10003;</span>{{ $t("sobre-goias.lista[8]") }}
        </p>
      </div>
      <h1 id="infraestrutura">{{ $t("sobre-goias.infraestrutura.titulo") }}</h1>
      <p class="lista">
        <span>&#10003;</span>{{ $t("sobre-goias.infraestrutura.lista[0]") }}
        <br />
        <span>&#10003;</span>{{ $t("sobre-goias.infraestrutura.lista[1]") }}
        <br />
        <span>&#10003;</span>{{ $t("sobre-goias.infraestrutura.lista[2]") }}
        <br />
        <span>&#10003;</span>{{ $t("sobre-goias.infraestrutura.lista[3]") }}
        <br />
        <span>&#10003;</span>{{ $t("sobre-goias.infraestrutura.lista[4]") }}
        <br />
        <span>&#10003;</span>{{ $t("sobre-goias.infraestrutura.lista[5]") }}
        <br />
        <span>&#10003;</span>{{ $t("sobre-goias.infraestrutura.lista[6]") }}
      </p>
      <div class="banner-video">
        <h2>{{ $t("sobre-goias.banner") }}</h2>
        <router-link :to="{name: EXIBIR_VIDEO}" target="_blank">
          <IconPlay />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import IconPlay from "../../assets/images/icon-play.svg";
import { EXIBIR_VIDEO } from "../../router/routeNames";

export default {
  name: "SobreGoias",
  components:{
    IconPlay,
  },
  data() {
    return {
      EXIBIR_VIDEO
    }
  }
};
</script>

<style lang="scss" scoped>
.parte2 {
  scroll-margin-top: 4.5rem;
  h1 {
    font-family: "Poppins-SemiBold";
    color: #256f0d;
  }
  p {
    text-align: justify;
    text-indent: 3rem;
  }
  .lista {
    text-indent: 0;
    span {
      color: #256f0d;
      font-size: 1.1rem;
      margin-right: 0.5rem;
    }
  }
  .row2 {
    display: grid;
    iframe {
      width: 100%;
      height: 20rem;
    }
  }
  .banner-video{
    background-image: url("../../assets/images/banner-video.png");
    background-size: cover;
    background-position: left;
    background-repeat: no-repeat;
    height: 19rem;
    margin: 1.5rem 0;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2{
      font-family: "Poppins-SemiBold";
      color: white;
      font-size: 2rem;
      text-align: center;
      margin: 0;
      margin-bottom: 2rem;
      text-shadow: 0 0 .5rem black;
    }
    a{
      text-decoration: none;
    }
    svg{
      transition: .2s linear;
    }
    a:hover{
      svg{
        width: 6rem;
        height: 6rem;
      }
    }
  }
}
.alto-contraste {
  h1 {
    color: #f7a311;
  }
  p {
    color: white;
  }
}

@media screen and (min-width: "50em") {
  .parte2 {
    margin-bottom: 1.5rem;
    .row2 {
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
      margin-top: 1rem;
      iframe {
        height: 17rem;
      }
    }
    p {
      margin: 0;
    }
    .banner-video{
      background-position: right;
      align-items: flex-start;
      h2{
        text-align: left;
        margin-left: 3rem;
        width: 65%;
      }
      svg{
        margin-left: 3rem;
        width: 7rem;
        height: 7rem;
      }
      a:hover{
        svg{
          height: 8rem;
          width: 8rem;
        }
      }
    }
  }
}
@media screen and (min-width: "70em"){
  .parte2{
    .banner-video{
    
      br{
        display: none;
      }
      svg{
        height: 9rem;
        width: 9rem;
      }
      a:hover{
        svg{
          height: 10rem;
          width: 10rem;
        }
      }
    }
  }
}
</style>
