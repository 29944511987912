<template>
  <div class="parte5" id="programas">
    <div class="centro">
      <h1>{{ $t("programas-incentivos.titulo") }}</h1>
      <div class="grupo">
        <img
          src="../../assets/images/logo-progoias.png"
          class="logo-diurno"
          alt="Logo do Programa ProGoiás"
        />
        <img
          src="../../assets/images/logo-progoias-branco.png"
          class="logo-noturno"
          alt="Logo do Programa ProGoiás"
        />
        <div class="text">
          <p>
            {{ $t("programas-incentivos.pro-goias-descricao-1-paragrafo") }}
          </p>
          <p>
            {{ $t("programas-incentivos.pro-goias-descricao-2-paragrafo") }}
          </p>
          <a href="https://www.economia.go.gov.br/progoias" target="blank">{{
            $t("programas-incentivos.acesse-site-pro-goias")
          }}</a>
        </div>
      </div>
      <div class="grupo">
        <img
          src="../../assets/images/logo-produzir.png"
          class="logo-diurno"
          alt="Logo do Programa ProGoiás"
        />
        <img
          src="../../assets/images/logo-produzir-branco.png"
          class="logo-noturno"
          alt="Logo do Programa Produzir"
        />
        <div class="text">
          <p>
            {{ $t("programas-incentivos.produzir-descricao-1-paragrafo") }}
          </p>
          <p>
            {{ $t("programas-incentivos.produzir-descricao-2-paragrafo") }}
          </p>
          <p>
            {{ $t("programas-incentivos.produzir-descricao-3-paragrafo") }}
          </p>
          <a
            href="https://www.sic.go.gov.br/fomento-e-competitividades/produzir.html"
            target="blank"
            >{{ $t("programas-incentivos.acesse-site-produzir") }}</a
          >
        </div>
      </div>
      <div class="grupo">
        <div>
          <h1>Comex</h1>
        </div>
        <div class="text">
          <p>
            {{ $t("programas-incentivos.comex-1-paragrafo") }}
          </p>
          <p>
            {{ $t("programas-incentivos.comex-2-paragrafo") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgramasIncentivo",
};
</script>

<style lang="scss" scoped>
.parte5 {
  h1 {
    font-family: "Poppins-SemiBold";
    color: #256f0d;
  }
  .grupo {
    margin: 2rem 0;
    text-align: center;
    img {
      width: 10rem;
    }
    .logo-noturno {
      display: none;
    }
    p {
      margin: 0;
    }
    a {
      padding: 0.2rem 2rem;
      display: table;
      margin: 0.5rem auto;
      text-decoration: none;
      color: #256f0d;
      border: #256f0d solid 0.1rem;
      transition: 0.2s;
      border-radius: 0.2rem;
    }
    a:hover {
      color: white;
      background-color: #256f0d;
    }
  }
}
div.text {
  p {
    text-indent: 2rem;
  }
}
.alto-contraste {
  h1 {
    color: #f7a311;
  }
  .grupo {
    p {
      color: white;
    }
    .logo-diurno {
      display: none;
    }
    .logo-noturno {
      display: block;
    }
    a {
      color: #f7a311;
      border: #f7a311 0.1rem solid;
    }
    a:hover {
      color: black;
      background-color: #f7a311;
    }
  }
}

@media screen and (min-width: "50em") {
  .parte5 {
    .grupo {
      display: grid;
      grid-template-columns: 20% 78%;
      gap: 2%;
      text-align: justify;
      img {
        width: 100%;
        margin-right: 1rem;
        align-self: flex-start;
      }
      a {
        margin: 0 0 0 auto;
      }
    }
  }
}
</style>
