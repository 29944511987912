require("dotenv").config();

import Vue from "vue";
import App from "./App.vue";

import router from "./router";
import "./assets/stylesheets/application.scss";
import i18n from './i18n'

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  i18n,
  router
}).$mount("#app");
