import axios from "axios";
import axiosInstance from "../axiosInstance";

export default class FormularioInformacoesMunicipaisApi {
  cancelTokens = {
    salvarFormularioInformacaoMunicipal: null,
    getCodigoMunicipioMapeamentoOportunidade: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }


  async salvarFormularioInformacaoMunicipal(formulario) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.salvarFormularioInformacaoMunicipal = source;

    const { data } = await axiosInstance.post(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/formulario_informacoes_municipais`,
      formulario,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.salvarFormularioInformacaoMunicipal = null;

    return data;
  }

  async getCodigoMunicipioMapeamentoOportunidade(codigo) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getCodigoMunicipioMapeamentoOportunidade = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/formulario_informacoes_municipais/codigo_municipio_mapeamento_oportunidade?codigo=${codigo}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getCodigoMunicipioMapeamentoOportunidade = null;

    return data;
  }
}
