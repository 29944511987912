import axios from "axios";
import Cookies from "js-cookie";

import { LOCALE_ATUAL_INVESTE_GOIAS } from "../config/constantes";

const instance = axios.create({});

instance.interceptors.request.use(
  function(config) {
    const locale = Cookies.get(LOCALE_ATUAL_INVESTE_GOIAS);

    if (locale) {
      config.headers["locale"] = locale;
    }

    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    return response;
  },
  function(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    return Promise.reject(error);
  }
);

export default instance;
