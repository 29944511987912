<template>
  <div class="acessibilidade">
    <div class="borda">
      <TheLocalization />
      <a title="Diminuir fonte" @click="diminuirFonte">-A</a>
      <a title="Fonte Média" @click="restaurarFonte">A</a>
      <a title="Aumentar Fonte" @click="aumentarFonte">+A</a>
      <a class="contraste" @click="aplicarContraste"><IconAcessibilidade /></a>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

import IconAcessibilidade from "../assets/images/icon-acessibilidade.svg";
import TheLocalization from "./TheLocalization";

export default {
  name: "TheAcessibilidade",
  components: {
    IconAcessibilidade,
    TheLocalization,
  },
  data() {
    return {
      alteracaoFonte: 0,
      altoContraste: false,
    };
  },
  mounted() {
    // aciona e retira autocontraste
    if (localStorage.getItem("autocontraste")) {
      $("body").addClass("alto-contraste");
      this.altoContraste = true;
    } else {
      $("body").removeClass("alto-contraste");
      this.altoContraste = false;
    }

    let alteracaoFonte = localStorage.getItem("tamanhoFonte");
    if (alteracaoFonte) {
      alteracaoFonte = parseInt(alteracaoFonte);
    } else {
      alteracaoFonte = 0;
    }

    this.alteracaoFonte = alteracaoFonte;

    this.aplicarFormatacao(
      parseInt($("body").css("font-size")) + this.alteracaoFonte
    );
  },
  methods: {
    aplicarContraste() {
      $("body").toggleClass("alto-contraste");
      this.armazenarContraste();
    },
    aumentarFonte() {
      if (this.alteracaoFonte < 4) {
        this.alteracaoFonte = this.alteracaoFonte + 1;

        this.armazenarAlteracaoFonte(this.alteracaoFonte);
        this.aplicarFormatacao(parseInt($("body").css("font-size")) + 1);
      }
    },
    diminuirFonte() {
      if (this.alteracaoFonte > -4) {
        this.alteracaoFonte = this.alteracaoFonte - 1;

        this.armazenarAlteracaoFonte(this.alteracaoFonte);
        this.aplicarFormatacao(parseInt($("body").css("font-size")) - 1);
      }
    },
    restaurarFonte() {
      $("html").css("font-size", "100%");
      const xsize = parseInt($("body").css("font-size"));
      const novoTamanho = xsize - this.alteracaoFonte;
      this.alteracaoFonte = 0;

      this.armazenarAlteracaoFonte(this.alteracaoFonte);
      this.aplicarFormatacao(novoTamanho);
    },
    aplicarFormatacao(tamanhoFonte) {
      $("html").css("font-size", tamanhoFonte + "px");
    },
    armazenarAlteracaoFonte(tamanho) {
      localStorage.setItem("tamanhoFonte", tamanho.toString());
    },
    armazenarContraste() {
      if (localStorage.getItem("autocontraste")) {
        localStorage.removeItem("autocontraste");
        this.altoContraste = false;
      } else {
        this.altoContraste = true;
        localStorage.setItem("autocontraste", "true");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.centro {
  display: grid;
}
.acessibilidade {
  display: flex;
  justify-content: center;
  justify-items: center;
  padding: 2rem 0;
  .borda {
    display: flex;
    justify-content: center;
    justify-items: center;
    border-bottom: white 0.1rem solid;
    border-top: white 0.1rem solid;
    padding: 1rem 0;
  }
  a {
    padding: 0 0.2rem;
    margin: 0 0.3rem;
    cursor: pointer;
    color: white;
    svg {
      height: 1rem;
      width: 1rem;
      fill: white;
    }
  }
  select {
    background-color: red !important;
  }
}

@media screen and (min-width: "64em") {
  .centro {
    position: relative;
    display: flex;
    padding: 0 0.5rem;
  }
  .acessibilidade {
    justify-content: flex-end;
    padding: 0;
    position: absolute;
    right: 0;
    top: -6rem;
    a {
      border-bottom: none;
      border-top: none;
      color: black;
      svg {
        fill: black;
      }
    }
  }
  .alto-contraste {
    .acessibilidade {
      .borda {
        border: none;
        a {
          color: white;
        }
        svg {
          fill: white;
        }
      }
    }
  }
}
</style>
