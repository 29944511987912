<template>
  <div class="parte4" id="linhas-credito">
    <div class="centro">
      <h1>{{ $t("linhas-credito.titulo") }}</h1>
      <p>{{ $t("linhas-credito.descricao") }}</p>
      <div class="cards">
        <div class="card">
          <img
            src="../../assets/images/mei-autonomos.png"
            class="img-mei"
            alt="Imagem de empreendedora de pequenos negócios"
          />
          <h3>{{ $t("linhas-credito.mei-autonomo") }}</h3>
          <p>
            {{ $t("linhas-credito.mei-autonomo-texto") }}
          </p>
        </div>
        <div class="card">
          <img
            src="../../assets/images/turismo.png"
            class="img-mei"
            alt="Imagem de turista olhando o por do sol"
          />
          <h3>{{ $t("linhas-credito.turismo") }}</h3>
          <p>
            {{ $t("linhas-credito.turismo-texto") }}
          </p>
        </div>
        <div class="card">
          <img
            src="../../assets/images/demais-setores.png"
            class="img-mei"
            alt="Imagem de engenheiros conversando"
          />
          <h3>{{ $t("linhas-credito.demais-setores") }}</h3>
          <p>
            {{ $t("linhas-credito.demais-setores-texto") }}
          </p>
        </div>
      </div>
      <a
        href="https://www.goiasfomento.com/linhas-de-credito-peame/"
        target="_blank"
        >{{ $t("linhas-credito.acesse-o-site-fomento") }}</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "LinhasCredito",
};
</script>

<style lang="scss" scoped>
.parte4 {
  h1 {
    font-family: "Poppins-SemiBold";
    color: #256f0d;
  }
  .cards {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .card {
      background-color: #e2e7e1;
      padding: 0.5rem;
      margin: 1rem 0.5rem;
      max-width: 15rem;
      img {
        width: 100%;
      }
      h3 {
        font-family: "Poppins-SemiBold";
        color: #f7a311;
        margin: 0;
        font-size: 1.5rem;
        text-align: center;
      }
      p {
        text-align: center;
        margin: 0;
      }
    }
  }
  a {
    display: table;
    margin: 1rem auto;
    color: #256f0d;
    text-decoration: none;
    padding: 0.3rem 2rem;
    border: 0.1rem solid #256f0d;
    transition: 0.3s;
    border-radius: .2rem;
  }
  a:hover {
    color: white;
    background-color: #256f0d;
  }
}
.alto-contraste {
  h1 {
    color: #f7a311;
  }
  p {
    color: white;
  }
  .cards {
    .card {
      background-color: #242424;
      h3 {
        color: #256f0d;
      }
    }
  }
  a {
    color: #f7a311;
    border: #f7a311 0.1rem solid;
  }
  a:hover {
    color: black;
    background-color: #f7a311;
  }
}
@media screen and (min-width: "50em") {
  .parte4 {
    .cards {
      display: flex;
    }
  }
}
</style>
