import axios from "axios";
import axiosInstance from "./axiosInstance";

export default class ContatosSiteApi {
  cancelTokens = {
    postContatoSite: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async postContatoSite({
    razaoSocial,
    nomeContato,
    numeroContato,
    emailContato,
    outrasInformacoes,
    token,
    eKey,
  }) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.postContatoSite = source;

    const { data } = await axiosInstance.post(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/contatos_site`,
      {
        razao_social: razaoSocial,
        nome_contato: nomeContato,
        numero_contato: numeroContato,
        email_contato: emailContato,
        outras_informacoes: outrasInformacoes,
        hcaptcha_token: token,
        hcaptcha_e_key: eKey,
      },
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.postContatoSite = null;

    return data;
  }
}
