<template>
  <div class="local">
    <div v-for="loc in localesDisponiveis" :key="loc.code">
      <a href @click="locale = loc.code"
        ><img :src="getIcon(loc.icon)" class="flag-icon"
      /></a>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";

import {
  LOCALE_ATUAL_INVESTE_GOIAS,
  LOCALES_DISPONIVEIS,
} from "../config/constantes";

export default {
  name: "TheLocalization",
  data() {
    return {
      locale: this.$i18n.locale,
      LOCALES_DISPONIVEIS,
      localesDisponiveis: Object.keys(LOCALES_DISPONIVEIS).map((loc) => ({
        label: LOCALES_DISPONIVEIS[loc].label,
        code: loc,
        icon: LOCALES_DISPONIVEIS[loc].icone,
      })),
    };
  },
  watch: {
    locale: function() {
      Cookies.set(LOCALE_ATUAL_INVESTE_GOIAS, this.locale);
      document.location.reload();
    },
  },
  methods: {
    getIcon(icon) {
      return require(`../assets/images/${icon}`);
    },
  },
};
</script>

<style lang="scss" scoped>

.local {
  display: flex;
  div{
    margin: 0 .5rem;
  }
  .flag-icon {
    // width: 50px;
    height: .8rem;
  }
}
@media screen and (min-width: "64em") {
  .local {
    select {
      color: black;
    }
  }
  .alto-contraste {
    select {
      color: white;
      option {
        background-color: #242424;
        color: white;
      }
    }
  }
}
</style>
