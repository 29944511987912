<template>
  <div class="dados-mapas">
    <div class="parte1">
      <div class="padding" v-if="carregando && !erro">
        <AppLoading texto="" />
      </div>
      <div v-if="!carregando && erro">
        <AppErro />
      </div>
      <div class="centro" v-if="!carregando && !erro">
        <h1 id="titulo-dados-mapa">{{ $t("dados-mapa.titulo") }}</h1>
        <div class="conteudo-dados">
          <h3>{{ $t("dados-mapa.frase-explicacao-acao") }}:</h3>
          <div class="row2">
            <div class="left">
              <CardSelect v-model="selecoes.polosIndustriais">
                <IconIndustriais />
                <span>{{ $t("dados-mapa.polos-industriais") }}</span>
              </CardSelect>
              <CardSelect v-model="selecoes.aeroportos">
                <IconAeroporto />
                <span>{{ $t("dados-mapa.aeroportos") }}</span>
              </CardSelect>
              <CardSelect v-model="selecoes.aerodromos">
                <IconAerodromos />
                <span>{{ $t("dados-mapa.aerodromos") }}</span>
              </CardSelect>
              <CardSelect v-model="selecoes.helipontos">
                <IconHeliporto />
                <span>{{ $t("dados-mapa.helipontos") }}</span>
              </CardSelect>
            </div>
            <div class="right">
              <vl-map
                :load-tiles-while-animating="true"
                :load-tiles-while-interacting="true"
                data-projection="EPSG:4326"
                style="height: 410px"
              >
                <vl-view
                  :zoom.sync="zoom"
                  :center="geolocPosition"
                  :rotation.sync="rotation"
                  projection="EPSG:4326"
                ></vl-view>

                <vl-geoloc>
                  <template v-if="selecoes.helipontos">
                    <vl-feature
                      :id="`heliponto-ponto-${heliponto.id}`"
                      :key="`${heliponto.id}_heliponto`"
                      v-for="heliponto in helipontos"
                      :properties="{ objeto: heliponto, type: 'heliponto' }"
                    >
                      <vl-geom-point
                        :coordinates="[heliponto.longitude, heliponto.latitude]"
                      ></vl-geom-point>
                      <vl-style-box>
                        <vl-style-icon
                          src="../assets/images/icon-maps-helicoptero.png"
                          :scale="0.1"
                          :anchor="[0.5, 1]"
                        ></vl-style-icon>
                      </vl-style-box>
                    </vl-feature>
                  </template>

                  <template v-if="selecoes.polosIndustriais">
                    <template v-for="polo in polos">
                      <vl-feature
                        :id="`heliponto-polo-${polo.id}`"
                        :key="`${polo.id}_polo`"
                        v-if="polo.latitude && polo.longitude"
                        :properties="{ objeto: polo, type: 'polo' }"
                      >
                        <vl-geom-point
                          :coordinates="[polo.longitude, polo.latitude]"
                        ></vl-geom-point>
                        <vl-style-box>
                          <vl-style-icon
                            src="../assets/images/icon-maps-industry.png"
                            :scale="0.12"
                            :anchor="[0.5, 1]"
                          ></vl-style-icon>
                        </vl-style-box>
                      </vl-feature>
                    </template>
                  </template>

                  <template v-if="this.selecoes.aerodromos">
                    <template v-for="aerodromo in aerodromos">
                      <vl-feature
                        :id="`heliponto-aerodromo-${aerodromo.id}`"
                        :key="`${aerodromo.id}_aerodromo`"
                        v-if="aerodromo.latitude && aerodromo.longitude"
                        :properties="{ objeto: aerodromo, type: 'aerodromo' }"
                      >
                        <vl-geom-point
                          :coordinates="[
                            aerodromo.longitude,
                            aerodromo.latitude,
                          ]"
                        ></vl-geom-point>
                        <vl-style-box>
                          <vl-style-icon
                            src="../assets/images/icon-maps-aerodromos.png"
                            :scale="0.3"
                            :anchor="[0.5, 1]"
                          ></vl-style-icon>
                        </vl-style-box>
                      </vl-feature>
                    </template>
                  </template>

                  <template v-if="this.selecoes.aeroportos">
                    <template v-for="aeroporto in aeroportos">
                      <vl-feature
                        :id="`heliponto-aeroporto-${aeroporto.id}`"
                        :key="`${aeroporto.id}_aeroporto`"
                        v-if="aeroporto.latitude && aeroporto.longitude"
                        :properties="{ objeto: aeroporto, type: 'aeroporto' }"
                      >
                        <vl-geom-point
                          :coordinates="[
                            aeroporto.longitude,
                            aeroporto.latitude,
                          ]"
                        ></vl-geom-point>
                        <vl-style-box>
                          <vl-style-icon
                            src="../assets/images/icon-maps-aeroporto.png"
                            :scale="0.1"
                            :anchor="[0.5, 1]"
                          ></vl-style-icon>
                        </vl-style-box>
                      </vl-feature>
                    </template>
                  </template>
                </vl-geoloc>
                <!-- caixa -->

                <!-- draw target -->

                <vl-interaction-select :features.sync="selectedFeatures">
                  <template slot-scope="select">
                    <!-- select styles -->
                    <vl-style-box>
                      <vl-style-stroke color="#423e9e" :width="7" />
                      <vl-style-fill :color="[254, 178, 76, 0.7]" />
                      <vl-style-circle :radius="5">
                        <vl-style-stroke color="#423e9e" :width="7" />
                        <vl-style-fill :color="[254, 178, 76, 0.7]" />
                      </vl-style-circle>
                    </vl-style-box>
                    <vl-style-box :z-index="1">
                      <vl-style-stroke color="#d43f45" :width="2" />
                      <vl-style-circle :radius="5">
                        <vl-style-stroke color="#d43f45" :width="2" />
                      </vl-style-circle>
                    </vl-style-box>
                    <!--// select styles -->

                    <!-- selected feature popup -->
                    <vl-overlay
                      v-for="feature in select.features"
                      :key="feature.id"
                      :id="feature.id"
                      class="feature-popup"
                      :position="pointOnSurface(feature.geometry)"
                      :auto-pan="true"
                      :auto-pan-animation="{ duration: 300 }"
                    >
                      <template>
                        <MapasOverlayCardPoloIndustrial
                          @close="
                            selectedFeatures = selectedFeatures.filter(
                              (f) => f.id !== feature.id
                            )
                          "
                          :polo-industrial="feature.properties.objeto"
                          v-if="feature.properties.type == 'polo'"
                        />
                        <MapasOverlayCardHelipontoAerodromoAeroporto
                          @close="
                            selectedFeatures = selectedFeatures.filter(
                              (f) => f.id !== feature.id
                            )
                          "
                          :heliponto-aerodromo-aeroporto="
                            feature.properties.objeto
                          "
                          v-if="
                            feature.properties.type == 'heliponto' ||
                              feature.properties.type == 'aerodromo' ||
                              feature.properties.type == 'aeroporto'
                          "
                        />
                      </template>
                    </vl-overlay>
                    <!--// selected popup -->
                  </template>
                </vl-interaction-select>
                <!-- caixa -->

                <vl-layer-tile id="osm">
                  <vl-source-osm></vl-source-osm>
                </vl-layer-tile>
              </vl-map>
            </div>
          </div>
        </div>
      </div>
    </div>

    <MapasTabela
      v-if="!carregando && !erro"
      :polos-industriais="polosIndustriais"
      :aeroportos="aeroportos"
      :aerodromos="aerodromos"
      :helipontos="helipontos"
    />

    <AppBotaoScroll
      elemento-referencia-id="#titulo-dados-mapa"
      v-if="!carregando && !erro"
    />
  </div>
</template>

<script>
import PolosIndustriaisApi from "../services/PolosIndustriaisApi";
import HelipontosAerodromosApi from "../services/HelipontosAerodromosApi";
import IconIndustriais from "../assets/images/factory.svg";
import IconAeroporto from "../assets/images/airplane.svg";
import IconAerodromos from "../assets/images/departures.svg";
import IconHeliporto from "../assets/images/helicopter.svg";

import AppLoading from "../components/AppLoading";
import AppErro from "../components/AppErro";
import CardSelect from "../components/CardSelect";
import MapasOverlayCardPoloIndustrial from "../components/dados_em_mapas/MapasOverlayCardPoloIndustrial";
import MapasOverlayCardHelipontoAerodromoAeroporto from "../components/dados_em_mapas/MapasOverlayCardHelipontoAerodromoAeroporto";
import MapasTabela from "../components/dados_em_mapas/MapasTabela";
import AppBotaoScroll from "../components/AppBotaoScroll";

import metadados from "../helpers/metadados";

export default {
  name: "DadosEmMapas",
  metaInfo() {
    return {
      meta: [...metadados(this.$route)],
    };
  },
  components: {
    AppLoading,
    AppErro,
    CardSelect,
    MapasOverlayCardPoloIndustrial,
    MapasOverlayCardHelipontoAerodromoAeroporto,
    IconIndustriais,
    IconAeroporto,
    IconAerodromos,
    IconHeliporto,
    MapasTabela,
    AppBotaoScroll,
  },
  data() {
    return {
      carregando: true,
      erro: false,
      polosIndustriais: [],
      helipontosAerodromos: [],
      //
      selecoes: {
        polosIndustriais: false,
        aerodromos: false,
        helipontos: false,
        aeroportos: false,
      },

      zoom: 6.5,
      center: [0, 0],
      rotation: 0,
      point: [-49.509597, -16.687931],
      geolocPosition: [-49.267898, -16.171584],
      selectedFeatures: [],
      drawnFeatures: [],
    };
  },
  created() {
    this.polosIndustriaisApi = new PolosIndustriaisApi();
    this.helipontosAerodromosApi = new HelipontosAerodromosApi();

    this.getData();
  },
  methods: {
    pointOnSurface(geometry) {
      return geometry.coordinates;
    },
    async getData() {
      try {
        this.erro = false;
        this.carregando = true;

        const polosIndustriais = await this.polosIndustriaisApi.getPolosIndustriais(
          { page: 1, perPage: 1000 }
        );

        this.polosIndustriais = polosIndustriais.resultados;

        const helipontosAerodromos = await this.helipontosAerodromosApi.getHelipontosAerodromos(
          { page: 1, perPage: 1000 }
        );

        this.helipontosAerodromos = helipontosAerodromos.resultados;

        this.carregando = false;
      } catch (err) {
        console.log(err);
        this.carregando = false;
        this.erro = true;
      }
    },
  },
  computed: {
    helipontos() {
      return this.helipontosAerodromos.filter(
        (heliponto) => heliponto.tipo === "heliponto"
      );
    },
    aerodromos() {
      return this.helipontosAerodromos.filter(
        (aerodromo) => aerodromo.tipo === "aerodromo"
      );
    },
    aeroportos() {
      return this.helipontosAerodromos.filter(
        (aeroporto) => aeroporto.tipo === "aeroporto"
      );
    },
    polos() {
      return this.polosIndustriais.map((polo) => {
        const ponto = polo.pontos.length > 0 ? polo.pontos[0] : {};
        return {
          ...polo,
          latitude: ponto.lat,
          longitude: ponto.long,
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dados-mapas {
  margin-top: 5rem;
  .parte1 {
    .padding {
      padding: 20rem 0;
    }
    .centro {
      h1 {
        font-family: "Poppins-SemiBold";
        color: #256f0d;
      }
      .conteudo-dados {
        h3 {
          margin: 0 0 0.5rem 0;
          font-family: "Poppins-SemiBold";
          color: #f7a311;
          font-size: 1rem;
        }
        .row2 {
          display: grid;
          .left {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 0.5rem;
            margin-bottom: 1rem;
          }
          .right {
            iframe {
              width: 100%;
              height: 24rem;
            }
          }
        }
        .center {
          margin-top: 1rem;
          table {
            width: 100%;
            border-collapse: collapse;
            text-align: left;
            thead {
              background-color: #256f0d;
              color: white;
              tr {
                padding: 0.2rem 0.5rem;
              }
            }
            tbody {
              td {
                width: 50%;
                padding: 0.2rem 0.5rem;
              }
              tr:nth-child(even) {
                background-color: #e2e7e1;
              }
            }
          }
        }
        .paginate {
          margin-top: 1rem;
          text-align: center;
          a {
            margin: 0 0.5rem;
          }
        }
      }
    }
  }
}
.alto-contraste {
  .dados-mapas {
    .parte1 {
      .centro {
        h1 {
          color: #f7a311;
        }
        .conteudo-dados {
          h3 {
            color: white;
          }
          .row2 {
            .left {
              label:nth-child(odd) {
                background-color: #242424;
              }
              span {
                color: white;
              }
            }
          }
          .center {
            table {
              tbody {
                tr:nth-child(even) {
                  background-color: #242424 !important;
                }
              }
            }
            td {
              color: white;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: "45em") {
  .dados-mapas {
    .parte1 {
      .centro {
        .conteudo-dados {
          .row2 {
            .left {
              grid-template-columns: 1fr 1fr 1fr 1fr;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: "64rem") {
  .dados-mapas {
    margin-top: 0;
    .parte1 {
      .centro {
        .conteudo-dados {
          .row2 {
            grid-template-columns: 1fr 3fr;
            align-items: center;
            gap: 1rem;
            .left1 {
              margin-top: 0rem;
            }
            .left {
              grid-template-columns: 1fr;
            }
            .right {
              height: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
