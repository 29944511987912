<template>
  <div class="parte3" id="industrias">
    <div v-if="carregando">
      <AppLoading texto="" />
    </div>
    <div v-if="!carregando && erro">
      <AppErro />
    </div>
    <div class="centro" v-if="!carregando && !erro">
      <h1>{{ $t("industria.titulo") }}</h1>
      <div class="selecionar-industria">
        <select name="Selecione" id="" v-model="categoriaSelecionadaId">
          <option
            v-for="categoriaIndustrial in categoriasIndustriais"
            :key="categoriaIndustrial.id"
            :value="categoriaIndustrial.id"
            >{{ categoriaIndustrial.nome }}</option
          >
        </select>
        <div class="botoes-industria">
          <button
            v-for="categoriaIndustrial in categoriasIndustriais"
            :key="categoriaIndustrial.id"
            @click="categoriaSelecionadaId = categoriaIndustrial.id"
            :class="{
              selecionado: categoriaSelecionadaId == categoriaIndustrial.id,
            }"
          >
            <component
              :is="icones[categoriaIndustrial.icone_svg]"
              v-if="
                categoriaIndustrial.icone_svg &&
                  icones[categoriaIndustrial.icone_svg]
              "
            ></component>
            {{ categoriaIndustrial.nome }}
          </button>
        </div>
        <div class="conteudo-industria" v-if="categoriaIndustrialSelecionada">
          <div class="left">
            <img
              :src="categoriaIndustrialSelecionada.imagem_url"
              class="img-industria"
              alt="Imagem e Industria de Alimentos"
            />
          </div>
          <div class="right">
            <h3>{{ categoriaIndustrialSelecionada.nome }}</h3>
            <div
              class="texto-industria"
              v-html="categoriaIndustrialSelecionada.texto"
            ></div>
            <!-- <a href="#">Conheça as empresas </a> -->
          </div>
        </div>
        <!-- <div class="tabela">
          <table>
            <thead>
              <th>Empresa</th>
              <th>Distrito</th>
            </thead>
            <tbody>
              <tr>
                <td>Lorem ipsum dolor sit amet</td>
                <td>Rio Verde</td>
              </tr>
              <tr>
                <td>Lorem ipsum dolor sit amet</td>
                <td>Rio Verde</td>
              </tr>
              <tr>
                <td>Lorem ipsum dolor sit amet</td>
                <td>Rio Verde</td>
              </tr>
            </tbody>
          </table>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import CategoriasIndustriaisApi from "../../services/CategoriasIndustriaisApi";

import AppLoading from "../AppLoading";
import AppErro from "../AppErro";

import IconAlimentos from "../../assets/images/industrias/icon-alimentos.svg";
import IconConstrucao from "../../assets/images/industrias/icon-construcao.svg";
import IconFarmaceutica from "../../assets/images/industrias/icon-farmaceutica.svg";
import IconCouros from "../../assets/images/industrias/icon-couro.svg";
import IconResiduos from "../../assets/images/industrias/icon-residuos.svg";
import IconMadeira from "../../assets/images/industrias/icon-madeira.svg";
import IconMaquinas from "../../assets/images/industrias/icon-maquina.svg";
import IconPlastico from "../../assets/images/industrias/icon-plastico.svg";
import IconMetal from "../../assets/images/industrias/icon-metais.svg";
import IconMineral from "../../assets/images/industrias/icon-minerais.svg";
import IconMoveleiro from "../../assets/images/industrias/icon-moveleiro.svg";
import IconPapel from "../../assets/images/industrias/icon-papel.svg";
import IconQuimico from "../../assets/images/industrias/icon-quimica.svg";
import IconTextil from "../../assets/images/industrias/icon-textil.svg";
import IconAgro from "../../assets/images/industrias/icon-agro.svg";
import IconAutomobilistica from "../../assets/images/industrias/icon-automoveis.svg";
import IconDefesa from "../../assets/images/industrias/icon-defesa.svg";
import IconEnergia from "../../assets/images/industrias/icon-energia.svg";
import IconLogistica from "../../assets/images/industrias/icon-logistica.svg";
import IconServicos from "../../assets/images/industrias/icon-servicos.svg";
import IconTic from "../../assets/images/industrias/icon-tic.svg";

export default {
  name: "Industrias",
  components: {
    AppLoading,
    AppErro,
  },
  data() {
    return {
      categoriasIndustriais: [],
      carregando: true,
      erro: false,
      categoriaSelecionadaId: null,
      icones,
    };
  },
  created() {
    this.categoriasIndustriaisApi = new CategoriasIndustriaisApi();
    this.carregarCategorias();
  },
  methods: {
    async carregarCategorias() {
      try {
        this.carregando = true;
        this.erro = false;

        this.categoriasIndustriais = await this.categoriasIndustriaisApi.getCategoriasIndustriais();
        if (this.categoriasIndustriais.length > 0) {
          this.categoriaSelecionadaId = this.categoriasIndustriais[0].id;
        }

        this.carregando = false;
      } catch (err) {
        console.log(err);
        this.erro = true;
        this.carregando = false;
      }
    },
  },
  computed: {
    categoriaIndustrialSelecionada() {
      if (
        this.categoriasIndustriais.length > 0 &&
        this.categoriaSelecionadaId
      ) {
        return this.categoriasIndustriais.find(
          (cat) => cat.id === this.categoriaSelecionadaId
        );
      }
      return null;
    },
  },
};

const icones = {
  "icon-alimentos": IconAlimentos,
  "icon-automoveis": IconAutomobilistica,
  "icon-construcao": IconConstrucao,
  "icon-farmaceutica": IconFarmaceutica,
  "icon-couro": IconCouros,
  "icon-residuos": IconResiduos,
  "icon-madeira": IconMadeira,
  "icon-maquinas": IconMaquinas,
  "icon-plastico": IconPlastico,
  "icon-metal": IconMetal,
  "icon-mineral": IconMineral,
  "icon-panel": IconPapel,
  "icon-moveleiro": IconMoveleiro,
  "icon-textil": IconTextil,
  "icon-quimico": IconQuimico,
  "icon-agro": IconAgro,
  "icon-defesa": IconDefesa,
  "icon-energia": IconEnergia,
  "icon-logistica": IconLogistica,
  "icon-servicos": IconServicos,
  "icon-tic": IconTic,
};
</script>

<style lang="scss" scoped>
.parte3 {
  background-color: #e2e7e1;
  padding-bottom: 2rem;
  h1 {
    font-family: "Poppins-SemiBold";
    color: #256f0d;
  }
  .selecionar-industria {
    select {
      width: 100%;
      border: none;
      background-color: transparent;
      border-bottom: solid 0.1rem #f7a311;
      color: #256f0d;
      font-size: 1rem;
      margin-bottom: 1rem;
      option {
        color: black;
      }
    }
    select:focus {
      outline: none;
    }
    .botoes-industria {
      display: none;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 0.5rem;
      margin-bottom: 1rem;
      button {
        padding: 0.5rem 0;
        display: flex;
        align-items: center;
        border-radius: 0.2rem;
        color: #256f0d;
        font-family: "Poppins-Medium";
        padding: 0.3rem;
        font-size: 1rem;
        border: #256f0d solid 0.15rem;
        background-color: transparent;
        cursor: pointer;
        transition: 0.2s;
        svg {
          height: 3rem;
          width: 3rem;
          margin-right: 0.5rem;
          fill: #256f0d;
          transition: 0.2s;
        }
      }
      button:hover {
        background-color: #256f0d;
        color: #e2e7e1;
        svg {
          fill: #e2e7e1;
        }
      }
    }
    .conteudo-industria {
      display: flex;
      flex-direction: column-reverse;
      .right {
        h3 {
          font-family: "Poppins-SemiBold";
          color: #f7a311;
          margin-bottom: 0;
          font-size: 1.5rem;
        }
        p {
          margin-top: 0;
        }
        a {
          color: #256f0d;
          border: #256f0d solid 0.1rem;
          text-decoration: none;
          padding: 0.3rem 1rem;
          margin-left: auto;
          margin-top: 2rem;
          display: table;
          transition: 0.2s;
          border-radius: 0.2rem;
        }
        a:hover {
          background-color: #256f0d;
          color: #e2e7e1;
        }
      }
      .left {
        display: none;
        img {
          width: 100%;
        }
      }
    }
    .tabela {
      table {
        border-collapse: collapse;
        width: 100%;
        margin-top: 1rem;
        thead {
          background-color: #256f0d;
          th {
            color: white;
            text-align: left;
            padding: 0.1rem 0.5rem;
          }
        }
        tbody {
          tr:nth-child(even) {
            background-color: white;
          }
        }
      }
    }
  }
}
.alto-contraste {
  .parte3 {
    background-color: #242424;
    h1 {
      color: #f7a311;
    }
    select {
      color: white;
      option {
        background-color: #242424;
        color: white;
      }
    }
    .botoes-industria {
      button {
        color: white;
        border: 0.15rem solid white;
        svg {
          fill: white;
        }
      }
      button:hover {
        color: #242424;
        background-color: white;
        svg {
          fill: #242424;
        }
      }
    }
    .conteudo-industria {
      .right {
        h3 {
          color: #256f0d;
        }
        a {
          color: #f7a311;
          border: #f7a311 solid 0.1rem;
        }
        a:hover {
          background-color: #f7a311;
          color: black;
        }
      }
    }
    .tabela {
      table {
        color: white;
        tbody {
          tr:nth-child(even) {
            background-color: black;
          }
        }
      }
    }
  }
}
@media screen and (min-width: "50em") {
  .parte3 {
    padding-bottom: 0;
    .selecionar-industria {
      select {
        display: none;
      }
      .botoes-industria {
        display: grid;
        margin-bottom: 2rem;
      }
    }
    .conteudo-industria {
      display: grid !important;
      grid-template-columns: 1fr;
      gap: 1rem;
      align-items: center;
      margin-bottom: 2rem;
      img {
        width: 100% !important;
        display: block;
      }
      .right {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        h3 {
          margin: 0;
        }
      }
      .left {
        display: block !important;
      }
    }
  }
}
</style>
