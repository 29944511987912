<template>
  <div class="centro">
    <video controls style="width: 100%; height: auto;">
      <source
        src="../assets/videos/video-portugues.mp4"
        type="video/mp4"
        v-if="locale == 'pt'"
      />
      <source src="../assets/videos/video-ingles.mp4" type="video/mp4" v-else />
    </video>
  </div>
</template>

<script>
export default {
  name: "ExibirVideo",
  data() {
    return {
      locale: this.$i18n.locale,
    };
  },
};
</script>


<style lang="scss" scoped>
video{
  margin: 7rem 0 1rem;
}
@media screen and (min-width: 64em){
  video{
    margin: 1rem 0;
  }
}
</style>
