<template>
  <div>
    <button @click="onPress" :class="{ selected: selected }">
      <slot></slot>
    </button>
  </div>
</template>

<script>
export default {
  name: "CardSelect",
  model: {
    prop: "selected",
    event: "update",
  },
  props: {
    selected: {
      type: Boolean,
    },
  },
  methods: {
    onPress() {
      this.$emit("update", !this.selected);
    },
  },
};
</script>

<style lang="scss" scoped>
div{
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  button{
    background-color: #e2e7e1;
    border: none;
    display: grid;
    cursor: pointer;
    font-size: 1rem;
    transition: .2s;
    height: 6rem;
    align-items: center;
    span{
      font-family: 'Poppins-Medium';
      color: #134104;
    }
    svg{
      height: 3rem;
      width: 3rem;
      margin: 0 auto;
      fill: #134104;
    }
  }
  button:hover{
    box-shadow: inset 0 0 .5rem #13410454;
    background-color: #c8d4c6;
  }
}
.selected{
  background-color: #134104;
  span{
    color: white;
  }
  svg{
    fill: white;
  }
}
.selected:hover{
  background-color: #134104;
}

.alto-contraste{
  div{
    background-color: red;
    button{
      background-color: #242424;
      span{
        color: white !important;
      }
      svg{
        fill: white;
      }
      
    }
    .selected{
      background-color: #134104;
    }
  }
}
</style>
